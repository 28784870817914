<script setup>
/* eslint-disable */
import { ref, computed ,onMounted} from 'vue';
import { BetSlip } from '@/stores/BetSlip';
import { useRouter } from 'vue-router';
import { useNavbarStore } from '@/stores/navbar_items';
import { useInitializeStore } from '@/stores/initialize';
import { useMenuStore } from '@/stores/menus';
import menuIcons from '@/assets/svgs/mobileNavigationSvgs.json'
import { useRacingSportsStore } from '@/stores/racing_sports';
import { openModal } from "@kolirt/vue-modal";
import Login from "@/components/modals/Login.vue";
import { useCustomerStore } from '@/stores/customer';
import VerificationEmail from "@/components/modals/VerificationEmail.vue";
import { EditBetSlip } from '@/stores/edit_betslip';

const router = useRouter();
const navbarStore = useNavbarStore();
const racingSportsStore = useRacingSportsStore();
const betSlipStore = BetSlip();
const editBetslipStore = EditBetSlip()
const initializeStore = useInitializeStore();
const menusStore = useMenuStore();
const customerStore = useCustomerStore();
const menu_icons = ref(menuIcons);
const showSportsMenu = ref(true);

const toggleModal = (type) => {
	if (!editBetslipStore.edit_mode_enabled) {
		betSlipStore.betSlipModel = betSlipStore.betSlipModel ? '' : type;
		if (betSlipStore.betSlipModel) navbarStore.closeAll();
	} else {
		editBetslipStore.betSlipModel = editBetslipStore.betSlipModel ? '' : 'betslip';
		if (editBetslipStore.betSlipModel) navbarStore.closeAll();
	}
}

const goToRoute = (menu) => {
	if (menu.click_url == '/pre-sports') {
		if (initializeStore.isMobile) {
			router.push({'name': 'pre-sports-coupons', params: {sport_id: ''}})
			return;
		}

		router.push({'name': 'pre-sports-highlights', params: {sport_id: ''}})
	} else if (menu.click_url == '/racing') {
		let first_sport = racingSportsStore.racingSports[0];

		router.push({name: 'racing-coupons', params: {sport_id: first_sport.id}});
		racingSportsStore.setSelectedRacingSportId(first_sport.id);

	} else {

		let login_required = (menu.click_url.includes('/account') || menu.click_url.includes('/casino/game/') || menu.click_url.includes('/live-casino/table/'));
		let verification_required = (menu.click_url.includes('/account') || menu.click_url.includes('/casino/game/') || menu.click_url.includes('/live-casino/table/'));

		if (login_required && !customerStore.isLoggedIn) openModal(Login).catch(() => {});
		else if (verification_required && !customerStore.hasVerifiedEmail) openModal(VerificationEmail).catch(() => {});
		else router.push(menu.click_url);
	}
}

const toggleMenu = () => {
	showSportsMenu.value = !showSportsMenu.value;
};

const isRouteActive = (menu) => {
	if (menu.click_url == null) return false;

	if (menu.click_url.includes('/live-sports') && router.currentRoute.value.path.includes('/live-sports')) return true;
	if (menu.click_url !== '/' && router.currentRoute.value.path.includes(menu.click_url)) return true;
	if (menu.click_url === '/' && router.currentRoute.value.name == 'landing' ) return true

	return false;
};

const getSvg = (menu) => {
	if (menu.click_url == null) return null;

	if (menu.click_url == '/pre-sports') return 'Sports';
	else if (menu.click_url.includes('/live-sports')) return 'Live-Sports';
	else if (menu.click_url == '/live-casino') return 'Live-Casino';
	else if (menu.click_url == '/casino') return 'Casino';
	else if (menu.click_url == '/racing') return 'Racing';
	else  return null
};

const columnsGridSports = computed(() => {
	let columns = '';
	// Generate the CSS for grid-template-columns property depending on menus length
	if (menusStore.getMobileNavigationMenuSports) {
		columns = `repeat(${menusStore.getMobileNavigationMenuSports.length + 1}, 1fr)`;
	}

	return columns;
});

const columnsGridCasino = computed(() => {
	let columns = '';
	// Generate the CSS for grid-template-columns property depending on menus length
	if (menusStore.getMobileNavigationMenuCasino) {
		columns = `repeat(${menusStore.getMobileNavigationMenuCasino.length + 1}, 1fr)`;
	}

	return columns;
});

onMounted(() => {
	if(["/live-casino","/casino"].includes(router.currentRoute.value.path)){
		showSportsMenu.value = false
	}
});
</script>

<template>
	<template v-if="menusStore.getMobileNavigationMenuSports || menusStore.getMobileNavigationMenuCasino">
		<!-- New fixed menu start -->
		<div class="menuFixed-mobile-main">

			<!-- first menu start-->
			<div class="menuFixed-mobile-inner" v-show="showSportsMenu" :style="{ 'grid-template-columns': columnsGridSports }">
				<template v-for="menu in menusStore.getMobileNavigationMenuSports">
					<div v-if="menu.name === 'Betslip'" class="menuFixed-item" id="popup-link" :class="[{ 'open': betSlipStore.betSlipModel || editBetslipStore.betSlipModel }]" @click="toggleModal('betslip')">
						<div class="menu-icon">
							<img src="@/assets/images/betslip.svg" alt="Betslip Icon">
						</div>
						<template v-if="editBetslipStore.edit_mode_enabled">
							<p>{{$t('general.betslip')}} <span v-if="editBetslipStore.extraBets">({{editBetslipStore.extraBets.length}})</span></p>
						</template>
						<template v-else>
							<p>{{$t('general.betslip')}} <span v-if="betSlipStore.countBetslipBets">({{betSlipStore.countBetslipBets}})</span></p>
						</template>
					</div>
					<div v-else class="menuFixed-item" :class="isRouteActive(menu) ? 'active': ''" @click="goToRoute(menu)">
						<div class="menu-icon">
							<img v-if="menu.media_url" :src="menu.media_url" class="image" width="25" height="25" />
							<p v-else v-html="menu_icons[getSvg(menu)]"></p>
						</div>
						<p>{{ menu.name }}</p>
					</div>
				</template>
				<div class="menuFixed-item menuFixed-toggle" @click="toggleMenu">
					<img src="@/assets/images/casino-toggle.svg" alt="Casino Toggle Icon">
				</div>
			</div>
			<!-- first menu end-->

			<!-- second menu start-->
			<div class="menuFixed-mobile-inner" v-show="!showSportsMenu" :style="{ 'grid-template-columns': columnsGridCasino }">
				<template v-for="menu in menusStore.getMobileNavigationMenuCasino">
					<div class="menuFixed-item" :class="isRouteActive(menu) ? 'active': ''"  @click="goToRoute(menu)">
						<div class="menu-icon">
							<img v-if="menu.name == 'Aviator'" src="@/assets/images/aviator.svg" alt="Aviator Icon">
							<img v-else-if="menu.media_url" :src="menu.media_url" class="image" width="25" height="25" />
							<p v-else v-html="menu_icons[getSvg(menu)]"></p>
						</div>
						<p>{{ menu.name }}</p>
					</div>
				</template>
				<div class="menuFixed-item menuFixed-toggle" @click="toggleMenu">
					<img src="@/assets/images/sports-toggle.svg" alt="Sports Toggle Icon">
				</div>
			</div>
			<!-- second menu end-->
		</div>
		<!-- New fixed menu End -->
	</template>
</template>

<style scoped>
html[dir="rtl"] .fixed-btm-main .fixed-btm-menu a:nth-child(1) {
	border-left: 1px solid #4E545D;
	border-right: 1px solid #4E545D;
}

.image {
	width: 25px;
	height: 25px;
	opacity: 45%
}

.fixed-btm-menu .router-link-active .image {
	opacity: unset !important;
}

/* .router-link-active .fixed-btm-inner .image {
	background: linear-gradient(359deg, #63A246, #131C27);
  /* opacity: 0.1; Adjust opacity for a more subtle effect (optional) */
/* } */

/* html[dir="rtl"] deep:(.container-full ) {
	padding-left: unset;
	padding-right: unset
} */
</style>
