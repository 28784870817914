<script setup>
import { ref, onMounted } from "vue"
import { BetSlip } from '@/stores/BetSlip';
import { useOddsStore } from '@/stores/odds';
import menuIcons from '@/assets/svgs/NotificationMenu.json'
import { EditBetSlip } from "@/stores/edit_betslip";

const oddsStore = useOddsStore();
const betslipStore = BetSlip();
const editBetslipStore = EditBetSlip();
const menu_icons = ref(menuIcons);

/* eslint-disable */
const props = defineProps({
	odd: {
		type: Object,
		required: true,
	},
	fixture: {
		required: true
	}
});

const participant_info = ref({});

onMounted(() => {
	participant_info.value = _.find(props.fixture.participants_info, { trapNumber: String(props.odd.number) });
});

const addToBetslip = () => {
	if (!props.odd || props.odd.status == 'suspended') return;

	if (editBetslipStore.edit_mode_enabled) editBetslipStore.toggleToBetslip({ uuid: props.odd.uuid, fixture_id: props.fixture.id })
	else betslipStore.toggleToBetslip({ odd_uuid: props.odd.uuid, fixture_id: props.fixture.id })
}

</script>
<template>
	<!-- upcoming common race element start -->
	<div class="upcoming-races-common">
		<div class="title">
			<p v-if="participant_info && participant_info.horseName">{{ participant_info.horseName }}</p>
			<p v-else>{{ odd.name }}</p>
		</div>
		<div class="upcoming-common-inner">
			<div class="upcoming-common-left">
				<!-- <div class="title">
					<p>{{ odd.name }}</p>
				</div> -->
			   <!-- <div class="star-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11.143" viewBox="0 0 12 11.143"><path id="Icon_ionic-ios-star" class="fixture-460957" data-name="Icon ionic-ios-star" d="M12.67,5.545H8.729l-1.2-3.573a.434.434,0,0,0-.814,0l-1.2,3.573H1.554a.43.43,0,0,0-.429.429.315.315,0,0,0,.008.072.412.412,0,0,0,.179.3L4.551,8.63,3.308,12.244a.43.43,0,0,0,.147.482.414.414,0,0,0,.241.1.525.525,0,0,0,.268-.1l3.161-2.253,3.161,2.253a.5.5,0,0,0,.268.1.385.385,0,0,0,.238-.1.425.425,0,0,0,.147-.482L9.7,8.63l3.212-2.3.078-.067a.449.449,0,0,0,.139-.287A.454.454,0,0,0,12.67,5.545Z" transform="translate(-1.125 -1.688)" fill="#4E545D"></path></svg>
				</div> -->
				<div class="racer-jearsy">
					<!-- <img src="../../assets/images/racing-jearsy.png" alt=""> -->
					<p>{{ odd.number }}</p>
				</div>

				<div class="race-info">
					<p v-if="participant_info && participant_info.jockeyName"><span>{{ $t('racing.jockey_short') }}:</span>{{ participant_info.jockeyName }}</p>
					<p v-if="participant_info && participant_info.trainerName"><span>{{ $t('racing.trainer_short') }}:</span>{{ participant_info.trainerName }}</p>
				</div>
			</div>

			<template v-if="odd && !oddsStore.isOddSuspended(odd)">
				<div class="upcoming-common-right" @click="addToBetslip">
					<div class="upcoming-odd-main" :class="{'active': odd && oddsStore.isOddInBetslip(odd.uuid) }" >
						<p>{{ odd.sp ? odd.placeholder : oddsStore.oddsFormat(odd.price) }}</p>
					</div>
				</div>
			</template>

			<template v-else>
				 <div class="upcoming-common-right">
					<div class="upcoming-odd-main">
						<p v-html="menu_icons.lock"></p>
					</div>
				</div>

			</template>
		</div>
	</div>
</template>