<script setup>
/* eslint-disable */

import { onBeforeUnmount, onMounted, watch, ref, computed } from "vue";
import { useRouter, useRoute } from 'vue-router';
import Header from '@/components/Common/header.vue'
import MobileMenu from "@/components/Common/mobileMenu.vue"
import MyBetsSlips from "./Bets/MyBetsSlips.vue"
import Footer from "@/components/Common/Footer.vue"
import Promotions from '@/components/esports/Promotions.vue';
import EsportSlider from '@/components/esports/EsportSlider.vue';
import { useFixtureStore } from '@/stores/fixture';
import { useRacingSportsStore } from '@/stores/racing_sports';
import { useInitializeStore } from '@/stores/initialize';

import LeftSidebarOverview from '@/components/live_sports/LeftSidebarOverview.vue';

import { useI18n } from 'vue-i18n';

import LeftSidebar from '@/components/esports/LeftSidebar.vue';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useRegionsStore } from '@/stores/regions';
import { EditBetSlip } from '@/stores/edit_betslip';
import MyEditBetsSlips from '@/views/Bets/MyEditBetsSlips.vue';

const route = useRoute();
const router = useRouter();
const preSportsStore = usePreSportsStore();
const regionsStore = useRegionsStore();
const fixtureStore = useFixtureStore();
const racingSportsStore = useRacingSportsStore();
const initializeStore = useInitializeStore();
const editBetslipStore = EditBetSlip();

const show_tablet_list = ref(false);
const { t } = useI18n();

const scrollToRegion = () => {
	var scrollableElement = document.getElementById('regions-container');
	var targetElement = document.getElementById('thechosenone');
	if (!targetElement) {
		return;
	}

	var targetOffset = targetElement.parentElement.offsetTop - 190;

	// Scroll to the target position
	scrollableElement.scrollTo({
		top: targetOffset,
		behavior: 'auto'
	});
}

onMounted(() => {
	window.scrollTo(0, 0);

	if (router.currentRoute.value.params.sport_id != '') {
		// Check if have sport_id in esports
		let sport = _.find(preSportsStore.esports, { 'id': Number(router.currentRoute.value.params.sport_id) });

		if (sport) preSportsStore.setSelectedEsportId(sport.id);
		else {
			preSportsStore.fetchPreSports().then((response) => {
				preSportsStore.setSelectedEsportId(preSportsStore.esports[0].id);
			});
		}

	} else {
		// Set first sport of pre sports
		preSportsStore.fetchPreSports().then((response) => {
			preSportsStore.setSelectedEsportId(preSportsStore.esports[0].id);
		});

		if (router.currentRoute.value.name == 'esports-upcoming') {
			router.push( { name: 'esports-upcoming', params: { sport_id: preSportsStore.selectedEsportId } } )
		}
	}

	setTimeout(() => {
		scrollToRegion();
	}, 200);


});
// Helper method to check if the tab is active based on the current route
const isTabActive = (tabName) => {
  return router.currentRoute.value.name === tabName;
};

const goToUpcoming = () => {
	router.push({ name: 'esports-upcoming', params: { sport_id: preSportsStore.selectedEsportId}})
}
const goToLive = () => {
	router.push({ name: 'esports-live', params: { sport_id: preSportsStore.selectedEsportId}})
}

// const goToCompetition = () => {
// 	let first_region = regionsStore.regionsCompetitions[0];

// 	let first_competition = first_region.competitions[Object.keys(first_region.competitions)[0]];

// 	router.push({ name: 'esports-competition', params: { sport_id: preSportsStore.selectedEsportId, region_id: first_region.id, competition_id: first_competition.id }});
// }

// const goToCoupons = () => {
// 	router.push({ name: 'esports-coupons', params: { sport_id: preSportsStore.selectedEsportId }});
// }

const getTabletActiveTab = computed(() => {
	if (router.currentRoute.value.name == 'esports-upcoming') return t('general.upcoming');
	if (router.currentRoute.value.name == 'esports-live') return t('general.live');
	// if (router.currentRoute.value.name == 'esports-coupons' || router.currentRoute.value.name == 'esports-coupons-next-hours') return t('general.coupons');
	// if (router.currentRoute.value.name == 'esports-competition' || router.currentRoute.value.name == 'esports-coupons-competitions') return t('general.competitions');
})

onBeforeUnmount(() => {
	preSportsStore.emptyPreSports();
})

watch(route, (to, from) => {


	if (to.params.sport_id == null || to.params.sport_id == '') {
		preSportsStore.setSelectedEsportId(preSportsStore.esports[0].id);

		if (router.currentRoute.value.name == 'esports-upcoming') {
			router.push( { name: 'esports-upcoming', params: { sport_id: preSportsStore.selectedEsportId } } )
		}

		return;
	} else {
		if (racingSportsStore.racing_sport_ids.includes(Number(to.params.sport_id))) {
			router.push( { name: 'racing-coupons', params: { sport_id: Number(to.params.sport_id) } } )
			return;
		}
	}

	if (to.params.sport_id == preSportsStore.selectedEsportId) return ;

	preSportsStore.setSelectedEsportId(to.params.sport_id);
});

watch(() => fixtureStore.fixtureBook, (newFixtureBook, oldFixtureBook) => {
	if (newFixtureBook.region_id == oldFixtureBook.region_id) return;

	setTimeout(() => {
		scrollToRegion();
	}, 200);
})



</script>
<template>
<div class="main_div">
	<Header></Header>

	<!-- fixed mobile bottom menu-->
	<div class="fixed-btm-main">
		<div class="container-full fix-padding">
			<MobileMenu></MobileMenu>
		</div>
	</div>

	<div class="sports-main esports-main" :class="{'esports-live_active': isTabActive('esports-live')}">
		<div class="container-full rtl-fix">
			<div class="sports-main-outer live_Sports-main-outer" :class="{ 'eventview-pannel': $route.name === 'live-Esports-eventview',' mobile-scoreboard-active': fixtureStore.mobile_active_tab == 'scoreboard'}">
				<div class="sports-outer-left">

					<!-- left sidebar  -->
					<div class="sports-left-sidebar">

						<div class="tab desktop-tab-left">
							<button :class="{'active': isTabActive('esports-upcoming')}" class="tablinks" @click="goToUpcoming">{{ $t('general.upcoming') }}</button>
                			<button :class="{'active': isTabActive('esports-live') && $route.name !== 'live-Esports-eventview'}" class="tablinks" @click="goToLive">{{ $t('general.live') }}</button>
							<!-- <button :class="{'active': router.currentRoute.value.name == 'esports-competition' || router.currentRoute.value.name == 'esports-coupons-competitions'}" class="tablinks" @click="goToCompetition">{{ $t('general.competitions') }}</button> -->
						</div>

						<div class="tablet-tab-left">
							<dl class="drop" :class="{'active': show_tablet_list}">
								<span class="tabIco">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414" viewBox="0 0 11.414 6.414">
										<g id="Group_795" data-name="Group 795" transform="translate(-1662.793 -579.793)">
											<line id="Line_5" data-name="Line 5" x2="5.066" y2="5" transform="translate(1663.5 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
											<line id="Line_6" data-name="Line 6" x1="5.066" y2="5" transform="translate(1668.434 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
										</g>
									</svg>
								</span>
								<dt><button class="tablinks" @click="show_tablet_list = !show_tablet_list">{{ getTabletActiveTab }}</button></dt>
								<dd>
									<ul>
										<li :class="''"><button @click="goToUpcoming" class="tablinks active">{{ $t('general.upcoming') }}</button></li>
										<li :class="''"><button @click="goToLive" class="tablink" >{{ $t('general.live') }}</button></li>
										<!-- <li :class="''"><button @click="goToCompetition" class="tablinks">{{ $t('general.competitions') }}</button></li> -->
									</ul>
								</dd>
							</dl>
						</div>

						<div class="accordion-outer" id="regions-container" v-if="!isTabActive('esports-live') && $route.name !== 'live-Esports-eventview'">
							<LeftSidebar/>
						</div>

						<template v-if="$route.name === 'live-Esports-eventview'">
							<LeftSidebarOverview/>
						</template>

					</div>
					<!-- left sidebar end -->

					<!-- center grid start -->
					<div class="sports-center-content" :class="{'competition-detail-center': router.currentRoute.value.name == 'esports-fixturebook'}">

						<!-- game filter slider start-->
						<div class="league-silder game-filter-slider">
							<div class="">
								<div id="game-top_Slider" class="league-slider-main game-slider_main loaded">
									<EsportSlider/>
								</div>
							</div>
						</div>

						<div id="highlights" class="tabcontent">

							<div class="highlight-tab-mobile">
								<div class="tab">
									<button @click="goToUpcoming" :class="{'active': router.currentRoute.value.name == 'esports-upcoming'}" class="tablinks">{{ $t('general.upcoming') }}</button>
									<button @click="goToLive" :class="{'active': router.currentRoute.value.name == 'esports-live' }" class="tablinks">{{ $t('general.live') }}</button>
								</div>
							</div>
						</div>

						<!-- Show either upcoming or live view -->
						<div>
							<router-view v-if="isTabActive('esports-upcoming')"></router-view>
							<router-view v-else-if="!isTabActive('esports-live')"></router-view>
						</div>
					</div>
					<div class="sports-center-content live-center-content" >
						<router-view v-if="isTabActive('esports-live')"></router-view>
					</div>
				</div>

				<div class="sports-outer-right">
					<div class="sports-promotion-outer">
						<div class="sports-banner-outer">
							<div class="promotion-header">
								<h2>{{ $t('general.promotions') }}</h2>
							</div>
						</div>
						<div class=" promotion-card-slider">
							<Promotions></Promotions>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>

	<!-- footer start -->
	<MyEditBetsSlips v-if="initializeStore.isSportsbookEnabled && editBetslipStore.edit_mode_enabled" />
	<MyBetsSlips v-if="initializeStore.isSportsbookEnabled && !editBetslipStore.edit_mode_enabled"/>

	<Footer></Footer>
</div>
</template>

<style scoped>
.main_div {
	background-color: #202833;
}

/* .sports-center-content {
	min-height: 700px;
} */

@media screen and (min-width: 1026px) and (max-width: 1277px) {
	.sports-promotion-outer {
		margin-top: 0px !important;
		padding: 0 !important;
		border: 0 !important;
	}
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
	.sports-outer-right {
		display: block;
		border-left: none;
		border-bottom: none;
		overflow: hidden;
	}
}

html[dir="rtl"] .sports-main .sports-main-outer .sports-left-sidebar .tab {
	justify-content: left;
}

html[dir="rtl"] .fix-padding {
	padding-left: unset;
	padding-right: unset;
}

@media screen and (min-width: 1281px) {
	html[dir="rtl"] .rtl-fix{
		padding-left: 0px !important;
		padding-right: 20px !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.sports-outer-right {
		border-left: none !important;
		border-right: none !important;
		border-bottom: none !important;
		overflow: hidden;
	}
}

@media screen and (max-width: 1280) {
	html[dir="rtl"] .sports-outer-right {
		border-left: none !important;
		border-top: none !important;
		border-right: none !important;
		border-bottom: none !important;
		border-radius: unset !important;
		padding: unset !important;
		height: -moz-fit-content;
		height: fit-content;
	}
}

html[dir="rtl"] .sports-outer-right {
    border-right: 1px solid #4E545D;
    border-bottom: 1px solid #4E545D;
    border-radius: 0 0 10px 0;
    padding: 25px 20px 20px 20px;
    height: -moz-fit-content;
    height: fit-content;
    border-left: unset;
}
</style>