<script setup>
/* eslint-disable */
import Header from '@/components/Common/header.vue';
import MobileMenu from '@/components/Common/mobileMenu.vue';
import RacingSportSlider from '@/components/racing_sports/RacingSportSlider.vue';
import RacingLeftSidebar from '@/components/racing_sports/RacingLeftSidebar.vue';
import RacingRightSidebar from '@/components/racing_sports/RacingRightSidebar.vue';
import MyBetsSlips from "./Bets/MyBetsSlips.vue"
import Footer from "@/components/Common/Footer.vue"
import { useRacingSportsStore } from '@/stores/racing_sports';
import { useInitializeStore } from '@/stores/initialize';
import { EditBetSlip } from '@/stores/edit_betslip';
import MyEditBetsSlips from '@/views/Bets/MyEditBetsSlips.vue';
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const racingSportsStore = useRacingSportsStore();
const initializeStore = useInitializeStore();
const editBetslipStore = EditBetSlip();

const collapsed_sidebar = ref(false);
// const collapsed_odds = ref(false);
const collapsed_upcoming = ref(false);

onMounted(() => {
	if (router.currentRoute.value.params.sport_id != '') {
		let sport_id = Number(router.currentRoute.value.params.sport_id);

		// Navigate to normal pre sports hightlight view
		if (!racingSportsStore.racing_sport_ids.includes(sport_id)) {
			console.log('Navigate to normal pre sports hightlight view');
			router.push({ name: 'pre-sports-highlights', params: { sport_id: sport_id }});
			return;
		}

		racingSportsStore.fetchNext3HoursFixtures(sport_id);
		racingSportsStore.setSelectedRacingSportId(sport_id);
	}
});

watch(() => racingSportsStore.selectedRacingSportId, (newValue, oldValue) => {
	if (newValue != null && oldValue != null) {
		racingSportsStore.fetchNext3HoursFixtures(newValue);
	}
})



</script>
<template>
	<div class="main_div">
		<Header></Header>

		<!-- fixed mobile bottom menu-->
		<div class="fixed-btm-main">
			<div class="container-full fix-padding">
				<MobileMenu></MobileMenu>
			</div>
		</div>

		<div class="racing-main">
			<div class="container-full">
				<div class="sports-main-outer live_Sports-main-outer racing-outer-main">
					<!-- Left  -->
					<div class="racing-outer-left">

						<!-- Sport SLider -->
						<RacingSportSlider></RacingSportSlider>

						<div class="race-main-area">
							<!-- racing banner start -->
							<div class="racing-banner-main">
								<div class="racing-banner-inner">
									<div class="racing-bg-image">
										<img src="../assets/images/horse-race-bg.png" alt="">
									</div>

									<div class="racing-banner-content">
										<h2><span>best odds</span> guaranteed</h2>
										<h6>On all UK and Irish races!</h6>

										<!-- <div class="race-disclaimer-main" :class="{ active: isActive }" @click="toggleActive">
											<div class="race-disclaimer-inner">
												<svg xmlns="http://www.w3.org/2000/svg" id="i" width="12" height="12" viewBox="0 0 12 12">
												<path id="Icon_awesome-info-circle" data-name="Icon awesome-info-circle" d="M6.562.563a6,6,0,1,0,6,6A6,6,0,0,0,6.562.563Zm0,2.661A1.016,1.016,0,1,1,5.546,4.24,1.016,1.016,0,0,1,6.562,3.224ZM7.917,9.369a.29.29,0,0,1-.29.29H5.5a.29.29,0,0,1-.29-.29V8.788A.29.29,0,0,1,5.5,8.5h.29V6.95H5.5a.29.29,0,0,1-.29-.29V6.079a.29.29,0,0,1,.29-.29H7.046a.29.29,0,0,1,.29.29V8.5h.29a.29.29,0,0,1,.29.29Z" transform="translate(-0.563 -0.563)" fill="#fff"/>
												</svg>
												<p>If you take the price and the starting price (SP) is bigger, we will pay you out at the bigger price. This applies to horse racing from UK and Ireland only. This offer applies to new and existing Betcom customers. T&Cs apply.</p>
												<span>Noted</span>
											</div>
										</div> -->
									</div>
								</div>
							</div>
							<!-- racing banner end -->

							<div class="racing-area-outer">
								<div class="racing_left-sidebar">
									<div class="title">
										<h4>{{ racingSportsStore.selectedSport ? racingSportsStore.selectedSport.name : '' }}</h4>
									</div>
									<RacingLeftSidebar></RacingLeftSidebar>
								</div>

								<!-- ROUTER VIEW -->
								<router-view></router-view>
								<!-- /ROUTER VIEW -->

							</div>

						</div>
					</div>

					<!-- Right Upcoming -->
					<div class="racing-outer-right">
						<RacingRightSidebar></RacingRightSidebar>
					</div>
				</div>
			</div>
		</div>

		<!-- footer start -->
		<MyEditBetsSlips v-if="initializeStore.isSportsbookEnabled && editBetslipStore.edit_mode_enabled" />
		<MyBetsSlips v-if="initializeStore.isSportsbookEnabled && !editBetslipStore.edit_mode_enabled"/>
		<Footer></Footer>
	</div>
</template>