<script setup>
/* eslint-disable */
import { onMounted } from 'vue';
import { useSocketStore } from '@/stores/socket';
import SingleBet from './SingleBet.vue';
import { EditBetSlip } from '@/stores/edit_betslip';

const socketStore = useSocketStore();
const editBetslipStore = EditBetSlip();

socketStore.getSocket.on('fixtureOddInfoInsert', (data) => {
	editBetslipStore.addFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoUpdate', (data) => {
	editBetslipStore.updateFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoDelete', (data) => {
	editBetslipStore.deleteFixtureOddInfo(data);
})

onMounted(() => {
	editBetslipStore.socketJoinBets();
})

</script>
<template>
	<div class="bet_slip_wrap tab-content " :class="editBetslipStore.betSlipModel == 'betslip' ? 'active open' :''"   id="tab1">
		<template v-if="editBetslipStore.countBetslipBets > 0">
			<div class="betslip-inner_content single-tab-content active">
				<SingleBet />
			</div>
		</template>

		<template v-else>
			<div class="bets_body" style="min-height: 100px;">
				<div class="bet-empty">
				<p>{{$t('betslip.you_havent_selected_any_bet')}} </p>
				<p>{{$t('betslip.you_can_add_bets_by_clicking_the_odds')}}</p>
				</div>
			</div>
		</template>
	</div>
</template>
<style scoped>
.disabled {
	pointer-events: none;
}

.grid {
	display: grid;
	grid-template-columns: 70% 20%;
	gap: 3%;
	padding: 10px 20px 20px 20px;
	align-items: center;
}

.text-field {
	position: relative;
}

.clear-button {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translate(-50%, -50%);
	cursor: pointer;
	width: 14px;
	color: #8E9298;
}

html[dir="rtl"] .clear-button {
	left: 16px;
	right: unset;
}

.share-btn {
	background: #FFBB02;
	color: #202833;
	border: 1px solid transparent;
	height: 24px;
	transition: 0.3s ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	transition: 0.3s ease-in-out;
	padding: 0px 5px 3px 5px;
	width: 100px;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.share-btn:hover {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
}

.btn-disabled {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
	pointer-events: none;
}

.form-input {
	width: 100%;
	padding: 5px 40px 5px 15px;
	background-color: #363D47;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 100;
	color: #8E9298;
}

html[dir="rtl"] .form-input {
	padding: 5px 15px 5px 40px;
}

@media screen and (max-width: 991px) {
	.grid {
		display: none;
	}
}

@media screen and (min-width: 992px) and (max-width: 1150px) {
	.grid {
		grid-template-columns: 1fr;
	}
	.share-btn {
		width: 100%;
		margin-top: 5px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.eventime_mobal_content .betslip-inner_content.active {
		padding-bottom: 0px;
	}
}
</style>