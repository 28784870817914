<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useOddsStore } from '@/stores/odds';
import { usePreSportsStore } from '@/stores/pre_sports';
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const liveSportsStore = useLiveSportsStore();
const oddsStore = useOddsStore();
const preSportsStore = usePreSportsStore();
const svg_data = ref(commonSvgs);

const props = defineProps({
	bet: {
		Type: Object,
		Required: true,
	}
});

</script>
<template>
	<div class="myBets-items" style="cursor:pointer;">
		<div class="bets_body">
			<div class="over_row">
				<div class="left">
					<div class="top_content" :style="!props.bet.live ? '' : ''">
						<div class="live-badge" v-if="props.bet.live" v-html="svg_data.live_badge"></div>
						<div class="over">
							<p>{{ props.bet.odd_name }}</p>
						</div>
					</div>
					<div class="subtitle">
						<p>{{ props.bet.market_name }}</p>
					</div>
				</div>
				<div class="right">
					<div class="value">
						<p>{{ oddsStore.oddsFormat(props.bet.odd_price) }}</p>
					</div>

					<div class="settlement-container" v-if="bet.settlement" style="width: 14px; height: 14px;">
						<img v-if="props.bet.settlement == 'WON' || bet.settlement == 'HALF_WON'" src="@/assets/images/light_circle_img.png" alt="WON">
						<img v-if="props.bet.settlement == 'LOST' || bet.settlement == 'HALF_LOST'" src="@/assets/images/pink_circle_img.png" alt="LOST">
						<img v-if="props.bet.settlement == 'PUSH' || bet.settlement == 'CANCELED' || bet.settlememt == 'REFUND'" src="@/assets/images/yellow_circle_img.png" alt="PUSH OR CANCELLED">
					</div>
				</div>
			</div>
			<div class="bottom_row">
				<div class="right">
					<div class="full_detail">
						<div class="icon">
							<img :src="preSportsStore.preSportWhiteIcon(props.bet.sport_id)" alt="card-image">
						</div>
						<div class="value">
							<div class="title_top">
								<p>{{ props.bet.region_name }} - {{ props.bet.competition_name }}</p>
							</div>

							<div v-if="liveSportsStore.hasOneParticipant(props.bet.sport_id) || props.bet.fixture_outright" class="title_bottom">
								<p>{{ props.bet.participants[0] }}</p>
							</div>

							<div v-else class="title_bottom">
								<p>{{ props.bet.participants[0] }} V {{ props.bet.participants[1] }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.deleted {
	text-decoration: line-through;
	color: #878A90;
}

.myBets-items {
	padding-bottom: 10px;
	border-bottom: 1px dashed #4E545D;
	padding-top: 0;
}

.bets_body .over_row {
	grid-auto-columns: auto auto;
	grid-gap: 1%;
	grid-auto-flow: column;
	display: grid;
	justify-content: space-between;
	align-items: flex-start;
	text-align: center;
	padding-left: 0%;
	gap: 5px;
	padding-top: 6px;
}

.bets_body .over_row .left {
	position: relative;
}

.bets_body .over_row .left .top_content {
	display: grid;
	grid-template-columns: auto auto;
	gap: 10px;
	align-items: center;
	text-align: center;
	justify-content: start;
}

.bets_body .over_row .left .top_content .over {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: start;
	display: grid;
}

.bets_body .over_row .left .top_content .over p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.bets_body .over_row .left .subtitle {
	font-size: 10px;
	color: #63A246;
	text-align: start;
	display: grid;
	padding-top: 5px;
}

.bets_body .over_row .left .subtitle p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.bets_body .over_row .right {
	font-size: 12px;
	font-weight: 500;
	color: #63A246;
	text-align: right;
	display: flex;
	gap: 10px;
	align-items: center;
}

.bets_body .over_row .right .value {
	position: relative;
	margin-top: 0;
	font-weight: bold;
	font-size: 14px;
	margin-right: 10px;
}

.bets_body .over_row .right .value.betup p {
	color: #63A246 !important;
}

.bets_body .over_row .right .clear-ico {
	display: flex;
	align-items: center;
	width: 20px;
}

.bets_body .bottom_row {
	grid-auto-columns: 1fr;
	gap: 27px;
	grid-auto-flow: column;
	display: grid;
	justify-content: start;
	align-items: self-start;
	text-align: center;
	padding-top: 3px;
}

.bets_body .bottom_row .right {
	position: relative;
}

.bets_body .bottom_row .right .full_detail {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 5px;
	align-items: center;
	text-align: center;
	justify-content: start;
	cursor: pointer;
}

.bets_body .bottom_row .right .full_detail .icon {
	height: 14px;
	width: 14px;
	position: relative;
	display: flex
;
}

.bets_body .bottom_row .right .full_detail .icon img {
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.bets_body .bottom_row .right .full_detail .value {
	position: relative;
}

.bets_body .bottom_row .right .full_detail .value .title_top {
	font-size: 12px;
	color: #fff;
	text-align: start;
	margin-bottom: 1px;
	font-weight: 200;
}

.bets_body .bottom_row .right .full_detail .value div p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}

.bets_body .bottom_row .right .full_detail .value .title_bottom {
	font-size: 12px;
	color: #fff;
	text-align: start;
	font-weight: 200;
}

.bets_body .bottom_row .right .full_detail .value div p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}

.live-badge {
	color: #131C27;
	background: #FFBB02;
	border-radius: 3px;
	font-size: 10px;
	width: 38px;
	height: 15px;
	text-align: center;
	margin-top: 2px;
	font-size: 600;
}

.suspended {
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}

.settlement-container {
	width: 14px;
	height: 14px;
}
</style>