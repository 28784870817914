<script setup>
import Loader from '@/components/casino_v2/LoaderComponent.vue';
// import FavoriteGames from '@/components/casino_v2/FavoriteGames.vue';
import LastPlayed from '@/components/casino_v2/LastPlayed.vue';
import Favourites from '@/components/casino_v2/Favourites.vue';
import ProviderGames from '@/components/casino_v2/ProviderGames.vue';
import Games from '@/components/casino_v2/AllGames.vue';
import CategoryGames from '@/components/casino_v2/CategoryGames.vue';
import SearchGames from '@/components/casino_v2/SearchGames.vue';
import { useCasinoStoreV2 } from '@/stores/casino_v2';

// Inialize store
const casinoStore = useCasinoStoreV2();

</script>
<template>
	<!-- Loader -->
	<Loader v-if="casinoStore.games_loading" />

	<template v-else>
		<!-- Games -->
		<Games v-if="(Object.keys(casinoStore.getGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'landing'"/>
		<CategoryGames v-else-if="(Object.keys(casinoStore.getGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'category'"/>
		<ProviderGames v-else-if="(Object.keys(casinoStore.getGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'provider'"/>
		<LastPlayed v-else-if="(Object.keys(casinoStore.getLastPlayedGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'last_played_games'"/>
		<Favourites v-else-if="(Object.keys(casinoStore.getFavoriteGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'favourites'"/>
		<SearchGames v-else-if="(Object.keys(casinoStore.getGames).length > 0 || casinoStore.getGamesLoadingMore == true) && casinoStore.getActivePage == 'search'"/>

		<!-- No Games Available -->
		<div v-if="Object.keys(casinoStore.getGames).length == 0 && Object.keys(casinoStore.getLastPlayedGames).length == 0 && Object.keys(casinoStore.getFavoriteGames).length == 0 && casinoStore.getGamesLoadingMore == false" class="main_cust_row no_games">
			<h6 class="text-color-and-align">{{ $t('general.slots_not_available') }}</h6>
		</div>
	</template>
</template>
<style scoped>
.text-color-and-align {
	text-align: center;
	color: #fff;
	font-weight: 300;
	margin-bottom: 50px;
	font-size: 20px;
}
</style>