<script setup>
import { onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import BrandsSlider from '@/components/casino_v2/BrandsSlider.vue';
import GamesContainer from '@/components/casino_v2/GamesContainer.vue';
import CategorySlider from '@/components/casino_v2/CategorySlider.vue';
import { useCasinoStoreV2 } from '@/stores/casino_v2';
import { useGeneralStore } from '@/stores/general';
import { useI18n } from 'vue-i18n';

// Inialize store
const router = useRouter();
const casinoStore = useCasinoStoreV2();
const generalStore = useGeneralStore();
const { t } = useI18n();

watch(() => router.currentRoute.value.params.category_slug, (category_slug) => {
	if (!category_slug) return;

	casinoStore.setSortType('popular');
	casinoStore.setSelectedCategorySlug(category_slug);
});

watch(() => casinoStore.getRedirectToLanding, (new_state) => {
	if (new_state == true) {
		casinoStore.setRedirectToLanding(false);
		router.push({ name: 'casino' });
	}
});

onMounted(() => {
	document.title = generalStore.getGeneral.site_name + ' - ' + t('routing.casino');

	document.body.style.overflow = 'auto';

	let current_route = router.currentRoute.value.name;
	let category_slug = null;

	if (current_route == 'casino-category') {
		category_slug = router.currentRoute.value.params.category_slug;
		casinoStore.setSelectedCategorySlug(category_slug);
	} else if (current_route == 'casino-favourites') {
		category_slug = 'favourites';
	} else if (current_route == 'casino-last-played') {
		category_slug = 'last_played_games';
	}

	casinoStore.resetCasinoParameters(category_slug);
});
</script>
<template>
	<!-------- Categories Slider Start --------->
	<div class="casino-tabing-header">
		<div class="container-lg casino-container-lg">

			<CategorySlider v-if="generalStore.isLicenseEnabled == 'true'"></CategorySlider>
		</div>
	</div>
	<!-------- Brand Slider End--------->

	<!---- Games Section Start ---->
	<div class="casino-game-area">
		<!-- Brand Slider Start -->
		<BrandsSlider></BrandsSlider>

		<div class="casino-game-section">
			<GamesContainer></GamesContainer>
		</div>
	</div>
	<!---- Games Section End ---->
</template>
<style scoped>
.casino-game-area {
	padding-bottom: 0;
}
</style>