/* eslint-disable */
import { defineStore } from "pinia";
import _ from "lodash";
import { MyBets } from "./MyBets.js";
import * as Combinatorics from "js-combinatorics";
import { applyPatch } from "rfc6902";
import { useSocketStore } from '@/stores/socket';
import { useCustomerStore } from "@/stores/customer.js";
import { closeModal } from '@kolirt/vue-modal';
import Swal from "sweetalert2";
import EditBet from "@/components/modals/edit_bet/EditBet.vue";

export const EditBetSlip = defineStore("EditBetSlip", {
	state: () => ({
		betslip_tax: 0,
		is_betslip_opened: false,
		tab_setted: false,
		same_fixture_error_message: false,
		betSlipModel: "",
		selectedTab: "singles",
		betslip: {
			type: "singles",
			bets: {},
			placed: false,
			error_message: null,
			multiple_freebet_id: null,
			multiple_stake: 0,
			systemX2_stake: 0,
			systemX3_stake: 0,
			systemX4_stake: 0,
			systemX5_stake: 0,
			systemX6_stake: 0,
			systemX7_stake: 0,
			systemX8_stake: 0,
			systemX9_stake: 0,
			systemX10_stake: 0,
			systemX11_stake: 0,
			systemX12_stake: 0,
			systemX13_stake: 0,
			systemX14_stake: 0,
			system_singles_stake: 0,
			system_multiple_stake: 0,
			old_betslip_id: null,
			extra_stake: 0,
		},
		deleted_bets: [],
		processing_placement: false,
		betslip_deleted: false,
		show_partial_cashout: false,
		partial_cashout_amount: 0,
		edit_loading: false,
		edit_mode_enabled: false,
		calculated_total_return: 0,
	}),
	getters: {
		isOddInBetslip: (state) => (odd_uuid) => {
			if (state.betslip.bets.hasOwnProperty(odd_uuid)) return true;
			else return false;
		},
		isOddSuspended: () => (odd) => {
			if (!odd) return true;
			else if (odd.hasOwnProperty("status") && odd.status == "suspended") return true;

			else return false;
		},
		isBetslipOpened(state) {
			return state.is_betslip_opened;
		},
		getSelectedTab(state) {
			return state.betslip.type;
		},
		countBetslipBets(state) {
			return _.filter(state.betslip.bets, (bet) => !bet.deleted).length;
		},
		calculateTotalStakes(state) {
			let single_bet_stakes = 0;
			let system_stakes = 0;

			for (const bet_uuid in state.betslip.bets) {
				if (Object.hasOwnProperty.call(state.betslip.bets, bet_uuid)) {
					const bet = state.betslip.bets[bet_uuid];

					if (!isNaN(bet.stake)) single_bet_stakes += Number(bet.stake);
				}
			}

			if (Object.keys(state.betslip.bets).length > 2) {
				for (let index = 2; index <= Object.keys(state.betslip.bets).length; index++) {
					let system_stake = state.betslip['systemX' + index + '_stake'];
					let combinations = Combinatorics.Combination.of(Object.values(state.betslip.bets), index);

					system_stake = Number(system_stake) * Number(combinations.length);

					if (!isNaN(system_stake)) system_stakes += Number(system_stake);
				}
			}

			let multiple_stake = state.betslip.multiple_stake;
			if (isNaN(multiple_stake)) multiple_stake = '0.00';

			let system_singles_stake = state.betslip.system_singles_stake;
			if (isNaN(system_singles_stake)) system_singles_stake = '0.00';

			let system_multiple_stake = state.betslip.system_multiple_stake;
			if (isNaN(system_multiple_stake)) system_multiple_stake = '0.00';

			let total_stake = Number(single_bet_stakes) + Number(multiple_stake) + Number(system_stakes) + Number(system_singles_stake) + Number(system_multiple_stake);

			return total_stake;
		},
		hasSPOdd(state) {
			let sp_odd_bets = _.filter(state.betslip.bets, function (betslip_bet) {
				return !betslip_bet.settlement && betslip_bet.odd_info.sp == true;
			});

			if (sp_odd_bets.length > 0) return true;

			return false;
		},
		getAvailableEditBetTypeOptions(state) {
			if (this.countBetslipBets == 1) return [{ type: 'single', name: "Single" }];
			if (this.countBetslipBets == 2) return [{ type: 'single', name: "Single" }, { type: 'multiple', name: "Multiple" }];
			if (this.countBetslipBets > 2) {
				let system_types = state.calculateSystemX;

				return [{ type: 'single', name: "Single" }, { type: 'multiple', name: "Multiple" }, ...system_types];
			}
		},
		extraBets() {
			return _.filter(this.betslip.bets, (bet) => !bet.initial_load);
		},
		getAllBets() {
			return Object.values(this.betslip.bets);
		},
		calculateSystemX(state) {
			let system_types = []
			for (let index = 0; index < (state.countBetslipBets - 2); index++) {
				system_types.push({ type: 'systemX' + (index + 2), name: 'System X' + (index + 2), system: index + 2 });
			}

			return system_types;
		},
		onlySettledBets(state) {
			let active_bets = _.filter(_.values(state.betslip.bets), bet => !bet.deleted);
			return _.every(active_bets, bet => bet.settlement != null);
		},
		canChangeType(state) {
			return !_.some(_.values(state.betslip.bets), bet => bet.settlement !== undefined && bet.settlement !== null);
		}
	},
	actions: {
		setBetslipTax(data) {
			this.betslip_tax = data;
		},
		toggleBetslip() {
			this.is_betslip_opened = !this.is_betslip_opened;
		},
		setSelectedTab(tab) {
			this.betslip.type = tab;
		},
		async toggleToBetslip(bet, initial_load = false) {
			const socketStore = useSocketStore();
			const myBetsStore = MyBets();
			let payload = {odd_uuid: bet.uuid, fixture_id: bet.fixture_id}

			if (this.betslip.placed == true) {
				this.resetBetslip();
			}

			if (this.betslip.bets.hasOwnProperty(bet.uuid)) {
				this.removeFromBetslip(payload, false, true);
				if (Object.keys(this.betslip.bets).length == 0) {
					this.betSlipModel = "";
				}

				return;
			}

			if (Object.keys(this.betslip.bets).length >= 50) {
				if (this.betSlipModel == "") this.betSlipModel = 'betslip';
				return;
			}

			if (bet.settlement) {
				this.betslip.bets[bet.uuid] = { ...myBetsStore.getSpecificBet(this.betslip.old_betslip_id, bet.uuid), deleted: false, initial_load: true, settlement: bet.settlement };
				return;
			}

			return global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/odds/info/?fixture_id=' + bet.fixture_id + '&odd_uuid=' + bet.uuid, { withCredentials: false }).then((response) => {
				let bet = {
					odd_info: response.data,
					price: response.data.price,
					has_error: false,
					stake: 0,
					freebet_id: null,
					deleted: false,
					settlement: null,
				}

				this.betslip.bets[bet.odd_info.uuid] = bet;

				if (initial_load) this.betslip.bets[bet.odd_info.uuid].initial_load = true;
				else this.betslip.bets[bet.odd_info.uuid].initial_load = false;

				socketStore.getSocket.emit('join-fixtureOddInfo', { fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid })

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);
				return Promise.reject(error.response.data);
			});
		},
		resetBetslip() {
			this.leaveOddInfos();

			// reset betslip state to default
			this.betslip.type = "singles";
			this.betslip.bets = {};
			this.betslip.placed = false;
			this.betslip.error_message = null;
			this.betslip.multiple_freebet_id = null;
			this.betslip.multiple_stake = 0;
			this.betslip.systemX2_stake = 0;
			this.betslip.systemX3_stake = 0;
			this.betslip.systemX4_stake = 0;
			this.betslip.systemX5_stake = 0;
			this.betslip.systemX6_stake = 0;
			this.betslip.systemX7_stake = 0;
			this.betslip.systemX8_stake = 0;
			this.betslip.systemX9_stake = 0;
			this.betslip.systemX10_stake = 0;
			this.betslip.systemX11_stake = 0;
			this.betslip.systemX12_stake = 0;
			this.betslip.systemX13_stake = 0;
			this.betslip.systemX14_stake = 0;
			this.betslip.system_singles_stake = 0;
			this.betslip.system_multiple_stake = 0;
			this.betslip.extra_stake = 0;
			this.betslip.betslip_stake = 0;
		},
		resetExtraBetslip() {
			for (const bet_id in this.betslip.bets) {
				if (!this.betslip.bets[bet_id].initial_load) {
					delete this.betslip.bets[bet_id];
				}
			}
		},
		addErrorMessageToBetslip() {
			let error_message = "The odds and/or availability of your selections have changed.";
			this.betslip.error_message = error_message;
		},
		removeFromBetslip(payload, deleted = false, user_selected = false) {
			if (!this.betslip.bets.hasOwnProperty(payload.odd_uuid)) return;
			if (this.countBetslipBets == 1 && !user_selected) return;

			if (deleted) this.betslip.bets[payload.odd_uuid].deleted = true;
			else this.betslip.bets[payload.odd_uuid].deleted = false;

			if (user_selected) delete this.betslip.bets[payload.odd_uuid];
			this.leaveFixtureOddInfo({ fixture_id: payload.fixture_id, odd_uuid: payload.odd_uuid });

			if (this.countBetslipBets == 1) this.betslip.type = this.getBetslipTypeObject('single');
			if (this.countBetslipBets >= 2) this.betslip.type = this.getBetslipTypeObject('multiple');
		},
		undoRemoveFromBetslip(payload) {
			if (!this.betslip.bets.hasOwnProperty(payload.odd_uuid)) return;

			this.betslip.bets[payload.odd_uuid].deleted = false;

			const socketStore = useSocketStore();
			if (Object.hasOwnProperty.call(this.betslip.bets, payload.odd_uuid)) {
				const bet = this.betslip.bets[payload.odd_uuid];
				socketStore.getSocket.emit('join-fixtureOddInfo', { fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid });

				global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/odds/info/?fixture_id=' + bet.odd_info.fixture_id + '&odd_uuid=' + bet.odd_info.uuid, { withCredentials: false }).then((response) => {
					//
				}).catch((error) => {
					if (error.response && error.response.status == 404) {
						this.addErrorMessageToBetslip();
						this.betslip.bets[odd_uuid].odd_info.status = 'suspended';
					} else {
						console.log(error);
					}
				});
			}

			if (this.countBetslipBets == 1) this.betslip.type = this.getBetslipTypeObject('single');
			if (this.countBetslipBets >= 2) this.betslip.type = this.getBetslipTypeObject('multiple');
		},
		removeBetslipErrors() {
			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {
					const bet = this.betslip.bets[odd_uuid];

					if (bet.settlement) continue;

					// Remove if suspended
					if (bet.odd_info.status == "suspended") {
						this.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});
						continue;
					}

					// Fix price
					if (this.betslip.bets[odd_uuid].price != this.betslip.bets[odd_uuid].odd_info.price) {
						this.betslip.bets[odd_uuid].price = this.betslip.bets[odd_uuid].odd_info.price;
					}

					// Remove has error
					this.betslip.bets[odd_uuid].has_error = false;
				}
			}

			// Remove error message from betslip
			this.betslip.error_message = null;
		},
		validateSameFixtures() {
			this.same_fixture_error_message = false;
			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {
					const bet = this.betslip.bets[odd_uuid];

					if (bet.settlement != null) continue;

					let same_fixtures = _.filter(this.betslip.bets, function (betslip_bet) {
						return !betslip_bet.settlement && betslip_bet.odd_info.fixture_id == bet.odd_info.fixture_id && betslip_bet.odd_info.uuid != bet.odd_info.uuid;
					});

					if (same_fixtures.length > 0) {
						for (const same_fixture_bet of same_fixtures) {
							this.betslip.bets[same_fixture_bet.odd_info.uuid].has_error = true;
							this.same_fixture_error_message = true;
						}
					} else {
						this.betslip.bets[odd_uuid].has_error = false;
					}
				}
			}
		},
		socketJoinBets() {
			const socketStore = useSocketStore();

			for (const odd_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, odd_uuid)) {

					const bet = this.betslip.bets[odd_uuid];

					if (bet.settlement) continue;

					socketStore.getSocket.emit('join-fixtureOddInfo', { fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid });

					global.axios.get(process.env.VUE_APP_SPORTSBOOK_API_URL + '/odds/info/?fixture_id=' + bet.odd_info.fixture_id + '&odd_uuid=' + bet.odd_info.uuid, { withCredentials: false }).then((response) => {
						//
					}).catch((error) => {
						if (error.response && error.response.status == 404) {
							this.addErrorMessageToBetslip();
							this.betslip.bets[odd_uuid].odd_info.status = 'suspended';
						} else {
							console.log(error);
						}
					});
				}
			}
		},
		async placeBetslip(t) {
			if (this.calculateTotalStakes == 0) {
				return;
			}

			let betslip = _.cloneDeep(this.betslip);

			betslip = this.formatBetslipForPlace(betslip)

			if (this.calculateTotalStakes == 0) return;
			const customerStore = useCustomerStore();
			const myBetsStore = MyBets();

			this.processing_placement = true;
			global.axios.post(process.env.VUE_APP_API_URL + '/betslip/edit/place', JSON.stringify(betslip), { headers: {'Content-Type' : 'application/json'} }).then((response) => {
				customerStore.fetchCustomer();
				myBetsStore.fetchMyBets();

				closeModal(EditBet);
				this.leaveOddInfos();

				Swal.fire({
					text: t('edit_betslip.edit_placed'),
					icon: 'success',
					confirmButtonText: t('account.register.close')
				});

			}).catch((error) => {
				if (error.response.status == 406 || error.response.status == 401) {
					if (error.response.data) {
						let type = _.cloneDeep(betslip.type);
						this.betslip = error.response.data;

						if (type == 'system') {
							let system_types = Object.entries(betslip).find(([key, value]) => key.startsWith('systemX') && value !== 0);

							if (system_types) {
								let system_size = Number(system_types[0].replace('systemX', '').replace('_stake', ''));
								let system_stake = system_types[1];

								let bets = Combinatorics.Combination.of(Object.values(this.betslip.bets), system_size);
								let bets_number;
								if (bets.length) {
									bets_number = String(bets.length);
									bets_number = Number(bets_number.replace('n', ''));
								}

								let total_system_stake = Number(system_stake) * Number(bets_number);
								if (this.betslip.extra_stake > 0) total_system_stake = Number(total_system_stake) - Number(this.betslip.extra_stake)

								this.betslip.type = this.getBetslipTypeObject(type, system_size);
								this.betslip.betslip_stake = total_system_stake;
							}

						} else if (type == 'multiple') {
							if (this.betslip.extra_stake > 0) this.betslip.multiple_stake = Number(this.betslip.multiple_stake) - Number(this.betslip.extra_stake);
							this.betslip.type = this.getBetslipTypeObject(type)
						} else {
							this.betslip.type = this.getBetslipTypeObject('single')
						}
					}
				} else {
					console.log(error);
				}
			}).finally(() => {
				this.processing_placement = false;
			});
		},
		addSingleStakeToBets(betslip) {
			for (const key in betslip.bets) {
				if (Object.hasOwnProperty.call(betslip.bets, key)) {
					betslip.bets[key].stake = betslip.system_singles_stake;
				}
			}

			return betslip;
		},
		addSingleBetsStakeToSystemSingle() {
			if (this.betslip.type != 'system') return;
			if (this.betslip.system_singles_stake == 0) return;

			let bet_stakes = [];
			for (const key in this.betslip.bets) {

				if (Object.hasOwnProperty.call(this.betslip.bets, key)) {
					bet_stakes.push(Number(this.betslip.bets[key].stake));
				}
			}

			const min_stake = Math.min(...bet_stakes);
			this.betslip.system_singles_stake = min_stake;
		},
		addSingleBetsStakeToSystemMultiple() {
			if (this.betslip.type != 'system') return;
			if (this.betslip.system_multiple_stake == 0) return;

			this.betslip.system_multiple_stake = this.betslip.multiple_stake
		},

		leaveOddInfos() {
			// leave odd info chanels
			for (const bet_uuid in this.betslip.bets) {
				if (Object.hasOwnProperty.call(this.betslip.bets, bet_uuid)) {
					const bet = this.betslip.bets[bet_uuid];
					if (bet.settlement) continue;

					this.leaveFixtureOddInfo({fixture_id: bet.odd_info.fixture_id, odd_uuid: bet.odd_info.uuid });
				}
			}
		},
		leaveFixtureOddInfo(payload) {
			const socketStore = useSocketStore();
			const myBetsStore = MyBets();
			let found = false;

			let betslips = _.filter(myBetsStore.my_bets, {
				betslip_result: "unsettled",
			});

			for (const element of betslips) {
				const betslip = element;
				let bet = _.find(betslip.bets, { uuid: payload.odd_uuid });
				if (bet) {
					found = true;
					break;
				}
			}

			if (!found) socketStore.getSocket.emit('leave-fixtureOddInfo', { fixture_id: payload.fixture_id, odd_uuid: payload.odd_uuid })
		},
		addFixtureOddInfo(data) {
			if (!this.betslip.bets.hasOwnProperty(data.uuid)) return;

			this.betslip.bets[data.uuid].odd_info = data;
			this.betslip.bets[data.uuid].price = data.price;
		},
		updateFixtureOddInfo(data) {
			const customerStore = useCustomerStore();


			let bet_cloned = _.cloneDeep(this.betslip.bets[data.uuid]);
			if (!bet_cloned) return;

			// Update bet
			applyPatch(bet_cloned.odd_info, data.patch);

			// Check price change for always_ask && accept_higher_odds
			if ((!bet_cloned.settlement && bet_cloned.price != bet_cloned.odd_info.price && customerStore.betslipChanges == "always_ask") ||
				(!bet_cloned.settlement && bet_cloned.price > bet_cloned.odd_info.price && customerStore.betslipChanges == "accept_higher_odds")) {
				this.addErrorMessageToBetslip();
			} else {
				bet_cloned.price = bet_cloned.odd_info.price;
			}

			if (!bet_cloned.settlement && bet_cloned.odd_info.status == "suspended") {
				this.addErrorMessageToBetslip();
			}

			// Add updated bet to betslip
			this.betslip.bets[data.uuid] = bet_cloned;
		},
		deleteFixtureOddInfo(data) {
			if (!this.betslip.bets.hasOwnProperty(data)) return;

			this.addErrorMessageToBetslip();
			this.betslip.bets[data].odd_info.status = "suspended";
		},
		validateInput(current_value, type, value) {
			current_value = current_value + "";

			switch (type) {
				case "add":
					if (current_value) current_value = parseFloat(current_value) + value;
					else current_value = value;
				break;
				case "append":
					if (value == "." && current_value.indexOf(".") == -1)
						current_value = current_value + value;
					else if (value != ".") current_value = current_value + "" + value;
				break;
				case "delete":
					current_value = current_value.slice(0, -1);
				break;
			}

			return current_value;
		},
		calculateMaxBet(payload) {
			return axios.post(process.env.VUE_APP_API_URL + '/betslip/max-bet', payload, { headers: {'Content-Type' : 'application/json'} }).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response.data);
			});
		},
		calculateTotalStake() {
			return Number(this.betslip.betslip_stake) + Number(this.betslip.extra_stake);
		},
		caclulateTotalReturn() {
			switch (this.betslip.type.type) {
				case 'single':
					let total_price = 0;
					for (const uuid in this.betslip.bets) {
						if (Object.hasOwnProperty.call(this.betslip.bets, uuid)) {
							let bet = this.betslip.bets[uuid];
							if (!bet.deleted && bet.odd_info != null && bet.odd_info.status != 'suspended') total_price += Number(bet.price);
							else if (!bet.deleted && bet.settlement) total_price += Number(bet.odd_price);
						}
					}

					this.calculated_total_return = Number(total_price) * ((Number(this.betslip.betslip_stake) + Number(this.betslip.extra_stake)));
					break;
				case 'multiple':
					let multiple_price = 1;
					for (const bet_uuid in this.betslip.bets) {
						if (Object.hasOwnProperty.call(this.betslip.bets, bet_uuid)) {
							const bet = this.betslip.bets[bet_uuid];
							if (!bet.deleted && bet.odd_info != null && bet.odd_info.status != 'suspended') multiple_price = multiple_price * bet.odd_info.price;
							else if (!bet.deleted && bet.settlement) multiple_price = multiple_price * Number(bet.odd_price);
						}
					}

					this.calculated_total_return = (Number(multiple_price) * (Number(this.betslip.betslip_stake)) + Number(this.betslip.extra_stake));
					break;
				default:
					let combinations = Combinatorics.Combination.of(Object.values(this.betslip.bets), this.betslip.type.system);

					let bets = Combinatorics.Combination.of(Object.values(this.betslip.bets), this.betslip.type.system);
					let bets_number;
					if (bets.length) {
						bets_number = String(bets.length);
						bets_number = Number(bets_number.replace('n', ''));
					}

					let total_stakes = (Number(this.betslip.betslip_stake) + Number(this.betslip.extra_stake)) / Number(bets_number);

					let return_possible = 0;
					[...combinations].forEach(combination => {
						let system_price = 1;
						combination.forEach(bet => {
							if (!bet.deleted && bet.odd_info != null && bet.odd_info.status != 'suspended') system_price = system_price * bet.odd_info.price;
							else if (!bet.deleted && bet.settlement) system_price = system_price * Number(bet.odd_price);
						})

						return_possible += total_stakes * system_price;
					});

					this.calculated_total_return = Number(return_possible);
					break;
			}
		},
		getBetslipTypeObject(type, system_number = null) {
			if (type == 'system' && system_number) {
				return _.find(this.getAvailableEditBetTypeOptions, { system: Number(system_number) });
			} else {
				return _.find(this.getAvailableEditBetTypeOptions, { type });
			}
		},
		formatBetslipForPlace(betslip) {
			// Delete bets that have deleted == true
			betslip.bets = _.filter(betslip.bets, (bet) => !bet.deleted);

			// Asign total stake
			if (betslip.type.type == 'single' || betslip.type.type == 'singles') {
				let bet_length = Object.values(betslip.bets).length;
				let total_stakes = (Number(betslip.betslip_stake) + Number(betslip.extra_stake)) / bet_length;

				Object.values(betslip.bets).forEach(bet => {
					bet.stake = total_stakes;
				});
			} else if (betslip.type.type == 'multiple') betslip.multiple_stake = Number(betslip.betslip_stake) + Number(betslip.extra_stake);
			else if (betslip.type.type.startsWith('systemX')) {
				let system_number = betslip.type.system;

				let bets = Combinatorics.Combination.of(Object.values(betslip.bets), system_number);
				let bets_number;
				if (bets.length) {
					bets_number = String(bets.length);
					bets_number = Number(bets_number.replace('n', ''));
				}

				let total_stakes = (Number(betslip.betslip_stake) + Number(betslip.extra_stake)) / Number(bets_number);

				betslip['systemX' + system_number + '_stake'] = total_stakes;
			}

			// Give the correct type as string
			if (betslip.type.type == 'single') betslip.type = 'singles';
			else if (betslip.type.type.startsWith('systemX')) betslip.type = 'system';
			else betslip.type = betslip.type.type;

			return betslip
		}
	},
});
