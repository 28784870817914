<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useOddsStore } from '@/stores/odds';
import { EditBetSlip } from '@/stores/edit_betslip';
import { useLanguagesStore } from '@/stores/languages';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useRouter } from 'vue-router';
import commonSvgs from '@/assets/svgs/commonSvgs.json'

const liveSportsStore = useLiveSportsStore();
const oddsStore = useOddsStore();
const editBetslipStore = EditBetSlip();
const languagesStore = useLanguagesStore();
const preSportsStore = usePreSportsStore();
const svg_data = ref(commonSvgs);
const router = useRouter();

const props = defineProps({
	bet: {
		Type: Object,
		Required: true,
	}
});

const deleteBet = () => {
	if (props.bet.deleted) return;
	editBetslipStore.removeFromBetslip({ odd_uuid: props.bet.odd_info.uuid, fixture_id: props.bet.odd_info.fixture_id }, true);
	editBetslipStore.caclulateTotalReturn();
}

const undoDelete = () => {
	if (!props.bet.deleted) return;
	editBetslipStore.undoRemoveFromBetslip({ odd_uuid: props.bet.odd_info.uuid, fixture_id: props.bet.odd_info.fixture_id });
	editBetslipStore.caclulateTotalReturn();
}

const goToFixture = (bet) => {
	if (bet.odd_info.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: bet.odd_info.fixture_id } });
		return;
	}

	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.odd_info.sport_id, region_id: bet.odd_info.region_id, competition_id: bet.odd_info.competition_id, fixture_id: bet.odd_info.fixture_id } });
}

</script>
<template>
	<div class="myBets-items" style="cursor:pointer;">
		<div class="bets_body">
			<div class="over_row" :class="{'suspended': props.bet.odd_info == null || props.bet.odd_info.status == 'suspended' }">
				<div class="left">
					<div class="top_content" :style="!props.bet.odd_info.live ? '' : ''">
						<div class="live-badge" v-if="props.bet.odd_info.live" v-html="svg_data.live_badge"></div>
						<div class="over">
							<p :class="{'deleted': props.bet.deleted}">{{ props.bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</p>
						</div>
					</div>
					<div class="subtitle">
						<p :class="{'deleted': props.bet.deleted}">{{ props.bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }}</p>
					</div>
				</div>
				<div class="right ">
					<div class="value" :class="{'betup': props.bet.odd_info.price > props.bet.price, 'betdown': props.bet.odd_info.price < props.bet.price}">
						<p>{{ props.bet.odd_info.sp ? props.bet.odd_info.placeholder : oddsStore.oddsFormat(props.bet.odd_info.price) }}</p>
					</div>
					<span class="seprater"></span>
					<template v-if="!props.bet.settlement">
						<div v-if="!props.bet.deleted" class="clear-ico betslip-button" @click="deleteBet()">
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 14.741">
								<g id="Group_1850" data-name="Group 1850" transform="translate(0.5 0.5)">
									<g id="delete" transform="translate(0)">
									<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
									<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
									<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
										<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
										<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
									</g>
									<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
										<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
									</g>
									</g>
								</g>
							</svg>
						</div>
						<svg v-else fill="#ffffff" height="18px" width="18px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 329.937 329.937" xml:space="preserve" @click="undoDelete()" style="rotate: -90deg;">
							<path id="XMLID_6_" d="M317.86,60.862l-86.666-17.238c-8.124-1.612-16.021,3.661-17.638,11.785l-17.24,86.668
								c-1.616,8.125,3.66,16.023,11.785,17.639c0.988,0.196,1.973,0.291,2.943,0.291c7.01,0,13.276-4.939,14.696-12.076l10.711-53.851
								c22.66,41.719,16.365,95.225-18.79,130.38c-21.441,21.44-49.596,32.159-77.76,32.157c-28.157-0.003-56.323-10.722-77.759-32.157
								c-42.876-42.876-42.876-112.642,0-155.518c5.858-5.857,5.858-15.355,0-21.213c-5.857-5.857-15.355-5.857-21.213,0
								c-54.573,54.573-54.573,143.37,0,197.943c27.29,27.29,63.125,40.934,98.972,40.93c35.838-0.003,71.689-13.647,98.973-40.93
								c26.317-26.317,40.876-61.307,40.995-98.523c0.076-23.59-5.662-46.318-16.525-66.544l48.664,9.68
								c8.127,1.613,16.022-3.66,17.638-11.786C331.262,70.375,325.985,62.479,317.86,60.862z"
							/>
						</svg>
					</template>
				</div>
			</div>
			<div class="bottom_row">
				<div class="right">
					<div class="full_detail" :class="{'suspended': props.bet.odd_info == null || props.bet.odd_info.status == 'suspended' }">
						<div class="icon">
							<img :src="preSportsStore.preSportWhiteIcon(props.bet.odd_info.sport_id)" alt="card-image">
						</div>
						<div class="value" @click="goToFixture(bet)">
							<div class="title_top">
								<p :class="{'deleted': props.bet.deleted}">{{ props.bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ props.bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}</p>
							</div>

							<div v-if="liveSportsStore.hasOneParticipant(props.bet.odd_info.sport_id) || props.bet.odd_info.fixture_outright" class="title_bottom">
								<p :class="{'deleted': props.bet.deleted}">{{ props.bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
							</div>

							<div v-else class="title_bottom">
								<p :class="{'deleted': props.bet.deleted}">{{ props.bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ props.bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.deleted {
	text-decoration: line-through;
	color: #878A90;
}

.myBets-items {
	padding-bottom: 10px;
	border-bottom: 1px dashed #4E545D;
	padding-top: 0;
}

.bets_body .over_row {
	grid-auto-columns: auto auto;
	grid-gap: 1%;
	grid-auto-flow: column;
	display: grid;
	justify-content: space-between;
	align-items: flex-start;
	text-align: center;
	padding-left: 0%;
	gap: 5px;
	padding-top: 6px;
}

.bets_body .over_row .left {
	position: relative;
}

.bets_body .over_row .left .top_content {
	display: grid;
	grid-template-columns: auto auto;
	gap: 10px;
	align-items: center;
	text-align: center;
	justify-content: start;
}

.bets_body .over_row .left .top_content .over {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: start;
	display: grid;
}

.bets_body .over_row .left .top_content .over p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.bets_body .over_row .left .subtitle {
	font-size: 10px;
	color: #63A246;
	text-align: start;
	display: grid;
	padding-top: 5px;
}

.bets_body .over_row .left .subtitle p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.bets_body .over_row .right {
	font-size: 12px;
	font-weight: 500;
	color: #63A246;
	text-align: right;
	display: flex;
	gap: 10px;
}

.bets_body .over_row .right .value {
	position: relative;
	margin-top: 0;
	font-weight: bold;
	font-size: 14px;
	margin-right: 10px;
}

.bets_body .over_row .right .value.betup p {
	color: #63A246 !important;
}

.bets_body .over_row .right .clear-ico {
	display: flex;
	align-items: center;
	width: 20px;
}

.bets_body .bottom_row {
	grid-auto-columns: 1fr;
	gap: 27px;
	grid-auto-flow: column;
	display: grid;
	justify-content: start;
	align-items: self-start;
	text-align: center;
	padding-top: 3px;
}

.bets_body .bottom_row .right {
	position: relative;
}

.bets_body .bottom_row .right .full_detail {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 5px;
	align-items: center;
	text-align: center;
	justify-content: start;
	cursor: pointer;
}

.bets_body .bottom_row .right .full_detail .icon {
	height: 14px;
	width: 14px;
	position: relative;
	display: flex
;
}

.bets_body .bottom_row .right .full_detail .icon img {
	height: 100%;
	width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.bets_body .bottom_row .right .full_detail .value {
	position: relative;
}

.bets_body .bottom_row .right .full_detail .value .title_top {
	font-size: 12px;
	color: #fff;
	text-align: start;
	margin-bottom: 1px;
	font-weight: 200;
}

.bets_body .bottom_row .right .full_detail .value div p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}

.bets_body .bottom_row .right .full_detail .value .title_bottom {
	font-size: 12px;
	color: #fff;
	text-align: start;
	font-weight: 200;
}

.bets_body .bottom_row .right .full_detail .value div p {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	display: block;
}

.live-badge {
	color: #131C27;
	background: #FFBB02;
	border-radius: 3px;
	font-size: 10px;
	width: 38px;
	height: 15px;
	text-align: center;
	margin-top: 2px;
	font-size: 600;
}

.suspended {
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}
</style>