<script setup>
/* eslint-disable */
import { closeModal } from '@kolirt/vue-modal';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useCurrenciesStore } from '@/stores/currencies';
import Bet from './Bet.vue';
import SettledBet from './SettledBet.vue';
import { EditBetSlip } from '@/stores/edit_betslip';
import Loader from '@/components/Common/Loader.vue';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useSocketStore } from '@/stores/socket';

const svg_data = ref(commonSvgs);
const currenciesStore = useCurrenciesStore();
const editBetslipStore = EditBetSlip();
const { t } = useI18n();
const router = useRouter();
const screenWidth = ref(null);
const betslip_type = ref({ type: null, name: null });
const socketStore = useSocketStore();
const can_change_type = ref(false);

const props = defineProps({
	betslip: {
		type: Object,
		Required: false,
	},
	is_done_editing: {
		type: Boolean,
		Required: false,
		default: false
	}
})

socketStore.getSocket.on('fixtureOddInfoInsert', (data) => {
	editBetslipStore.addFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoUpdate', (data) => {
	editBetslipStore.updateFixtureOddInfo(data);
})

socketStore.getSocket.on('fixtureOddInfoDelete', (data) => {
	editBetslipStore.deleteFixtureOddInfo(data);
})

watch(() => props.betslip, (newBetslip, oldBetslip) => {
		betslip_type.value = editBetslipStore.getBetslipTypeObject(newBetslip.betslip_type);
		editBetslipStore.betslip.betslip_stake = newBetslip.cashout_amount;

		Object.values(editBetslipStore.betslip.bets).forEach(bet => {
			bet.stake = props.betslip.cashout_amount;
		});
	},
	{ deep: true }
);

watch(() => editBetslipStore.betslip.extra_stake, (newValue, oldValue) => {
	if (newValue) {
		Object.values(editBetslipStore.betslip.bets).forEach(bet => {
			bet.stake = Number(bet.stake) - Number(oldValue)
			bet.stake = Number(bet.stake) + Number(newValue)
		});
		editBetslipStore.caclulateTotalReturn();
	}
})

watch(() => betslip_type.value, () => {
	editBetslipStore.betslip.type = editBetslipStore.getBetslipTypeObject(betslip_type.value.type);
	editBetslipStore.caclulateTotalReturn();
})

watch(() => editBetslipStore.betslip.type, () => {
	if (editBetslipStore.betslip.type.type != betslip_type.value.type) {
		betslip_type.value = editBetslipStore.betslip.type;
	}
})

const validateStake = (event) => {
	if (event.key === ',') {
		event.preventDefault();
		if (!event.target.value.includes('.')) event.target.value += '.';
	}

	if (
		(event.key < '0' || event.key > '9') &&
		event.key !== '.' &&
		event.key !== 'Backspace'
	) {
		event.preventDefault();
	}

}

const closeEditModal = () => {
	closeModal();
}

const addMoreBets = () => {
	editBetslipStore.edit_mode_enabled = true;
	closeModal();
	router.push({'name': 'pre-sports-highlights', params: {sport_id: 1}})
	Swal.fire({
		text: t('edit_betslip.select_bets'),
		icon: 'info',
		confirmButtonText: t('account.register.close')
	});
}

const resizeHandler = () => {
	screenWidth.value = window.innerWidth;
}

const placeBet = () => {
	if (editBetslipStore.onlySettledBets || editBetslipStore.processing_placement) return;
	if (editBetslipStore.betslip.betslip_stake == null || Number(editBetslipStore.betslip.betslip_stake) == 0) return;

	editBetslipStore.placeBetslip(t);
}

const acceptChanges = () => {
	editBetslipStore.removeBetslipErrors();
}

onBeforeMount(async () => {
	if (!props.is_done_editing) {
		editBetslipStore.edit_loading = true;

		editBetslipStore.betslip.old_betslip_id = props.betslip.betslip_id;

		const bet_promises = props.betslip.bets.map(bet => {
			return editBetslipStore.toggleToBetslip(bet, true);
		});

		editBetslipStore.betslip.type = props.betslip.betslip_type;
		editBetslipStore.betslip.betslip_stake = props.betslip.cashout_amount;

		try {
			await Promise.all(bet_promises); // Wait for all bets to be added

			Object.values(editBetslipStore.betslip.bets).forEach(bet => {
				bet.stake = props.betslip.cashout_amount;
			});

		} catch (error) {
			closeEditModal();
			Swal.fire({
				text: t('edit_betslip.betslip_no_edit'),
				icon: 'error',
				confirmButtonText: t('account.register.close'),
			});
		} finally {
			if (props.betslip.betslip_type == 'system') {
				let system_number = props.betslip.betslip_system_number;
				betslip_type.value = editBetslipStore.getBetslipTypeObject(props.betslip.betslip_type, system_number);
			} else {
				betslip_type.value = editBetslipStore.getBetslipTypeObject(props.betslip.betslip_type);
			}
			editBetslipStore.edit_loading = false;
		}
	} else {
		betslip_type.value = editBetslipStore.betslip.type;
	}
});

onMounted(() => {
	screenWidth.value = window.innerWidth;
	window.addEventListener("resize", resizeHandler);
	editBetslipStore.socketJoinBets();
	can_change_type.value = editBetslipStore.canChangeType;
})

onUnmounted(() => {
	window.removeEventListener("resize", resizeHandler);
	if (!editBetslipStore.edit_mode_enabled) editBetslipStore.resetBetslip();
})

</script>
<template>
	<div class="register-panel login-pannel">
		<div class="top-info-bar">
			<div class="header-container">
				<template v-if="!editBetslipStore.edit_loading">
					<select v-if="can_change_type" class="form-input form-control" id="language-field" v-model="betslip_type">
						<option v-for="option in editBetslipStore.getAvailableEditBetTypeOptions" :value="option" :key="option.id" :selected="option.type == betslip_type.type">
							{{ option.name }}
						</option>
					</select>
					<div v-else class="betslip-type">
						<p>{{ betslip_type.name }}</p>
					</div>
				</template>
			</div>

			<button class="close" @click="closeEditModal()"  v-html="svg_data.popup_cross"></button>
		</div>

		<div class="content">
			<div v-if="editBetslipStore.edit_loading">
				<Loader/>
			</div>
			<div v-else class="bets-container">
				<template v-for="bet in editBetslipStore.betslip.bets" :key="bet.id">
					<Bet v-if="!bet.settlement" :bet="bet" />
					<SettledBet v-else :bet="bet" />
				</template>
				<div class="add-bets-items" style="cursor:pointer;" @click="addMoreBets()">
					<div class="add-bets">
						<span>
							<i class="fa fa-plus" aria-hidden="true" style="margin-right: 2px;"></i>
							&nbsp;{{ $t('edit_betslip.add_more_bets') }}
						</span>
					</div>
				</div>
			</div>
		</div>

		<div class="bottom-info-bar">
			<template v-if="editBetslipStore.betslip.error_message">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ editBetslipStore.betslip.error_message }}</p>
					</div>
				</div>
			</template>
			<div v-if="!editBetslipStore.edit_loading" class="bottom-container">
				<div class="new-stake-container">
					<p>{{ t('edit_betslip.new_stake') }}</p>
					<div class="new-stake-info">
						<p>{{ currenciesStore.format_currency(editBetslipStore.betslip.betslip_stake) }} + </p>
						<div class="right_content_85 stake-input">
							<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
							<input v-model="editBetslipStore.betslip.extra_stake" type="text" @keypress="validateStake($event)" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn">
						</div>
					</div>
				</div>

				<div class="new-return-container">
					<p>{{ t('edit_betslip.new_return') }}</p>
					<div class="new-return-info">
						<p>{{ currenciesStore.format_currency(editBetslipStore.calculated_total_return) }}</p>
					</div>
				</div>

				<template v-if="!editBetslipStore.betslip.error_message">
					<div v-if="screenWidth >= 600" class="button-container">
						<button v-if="!editBetslipStore.processing_placement" class="btn-small btn-secondary betslip-button" @click="placeBet()">{{ $t('betslip.place_bet') }} {{ currenciesStore.format_currency(editBetslipStore.calculateTotalStake()) }}</button>
						<button v-else class="btn-small btn-secondary betslip-button">
							<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
						</button>
					</div>
				</template>
				<template v-else>
					<div v-if="screenWidth >= 600" class="button-container">
						<button class="btn-small btn-secondary betslip-button" @click="acceptChanges()">{{ $t('betslip.accect_changes') }}</button>
					</div>
				</template>
			</div>
		</div>
		<template v-if="!editBetslipStore.betslip.error_message">
			<div v-if="!editBetslipStore.edit_loading && screenWidth < 600">
				<div class="button-container-mobile">
					<button v-if="!editBetslipStore.processing_placement" class="btn-small btn-secondary betslip-button" @click="placeBet()">{{ $t('betslip.place_bet') }} {{ currenciesStore.format_currency(editBetslipStore.calculateTotalStake()) }}</button>
					<button v-else class="btn-small btn-secondary betslip-button">
						<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
					</button>
				</div>
			</div>
		</template>
		<template v-else>
			<div v-if="!editBetslipStore.edit_loading && screenWidth < 600">
				<div class="button-container-mobile">
					<button class="btn-small btn-secondary betslip-button" @click="acceptChanges()">{{ $t('betslip.accect_changes') }}</button>
				</div>
			</div>
		</template>

		<template v-if="!props.is_done_editing">
			<div v-if="!props.betslip.edit_bet_available || !props.betslip.cashout_amount || props.betslip.cashout_amount == 0" class="no-edit-bet">{{ t('edit_betslip.not_available') }}</div>
		</template>
	</div>
</template>
<style scoped>
.register-panel {
	width: 60% !important;
	height: 80% !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 8px;
}

.top-info-bar {
	margin-top: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #4E545D;
}

.bottom-info-bar {
	padding-bottom: 20px;
	display: block;
	border-top: 1px solid #4E545D;
}

.close {
	cursor: pointer;
	padding-right: 20px;
}

@media screen and (min-width: 320px) and (max-width: 860px) {
	.register-panel {
		width: 100% !important;
		height: 100% !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1242px) {
	.register-panel {
		width: 100% !important;
		height: 100% !important;
	}
}

@media screen and (max-width: 991px) {
	.search-main-area {
		max-height: 660px;
	}
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
	.search-main-area {
		max-height: 660px;
	}
}

html[dir="rtl"] .top-search-bar .header-search-field button {
	right: unset;
	left: 14px;
}

.header-container {
	color: #FFBB02;
	display: flex;
	align-items: center;
	gap: 12px;
	padding-left: 20px;
}

.no-edit-bet {
	text-align: center;
	font-weight: 600;
	color: #ffffff;
}

.stake-input {
	display: inline-flex;
	max-width: 95px;
	width: 100%;
	background: transparent;
	border-radius: 5px;
	text-align: center;
	align-items: center;
	gap: 2px;
	overflow: hidden;
	color: #fff;
	font-size: 12px;
	border-radius: 1px;
	font-weight: 100;
	z-index: 2;
	position: relative;
}

.euro-icon {
	position: absolute;
	-webkit-padding-start: 6px;
	padding-inline-start: 6px;
	z-index: 2;
}

.stake-input input {
	text-align: end;
	width: inherit;
	border-radius: 1px;
	height: 30px;
	border: 1px solid #878A90;
	position: relative;
	-webkit-padding-start: 20px;
	padding-inline-start: 20px;
	-webkit-padding-end: 5px;
	padding-inline-end: 5px;
	border-radius: 3px;
}

.bottom-container {
	padding-right: 20px;
	padding-left: 20px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.new-stake-container {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.new-stake-container p {
	color: #fff;
	font-weight: 600;
	font-size: 18px;
}

.new-stake-info {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-top: 6px;
}

.new-stake-info p {
	color: #fff;
	font-size: 18px;
	font-weight: 300;
}

.new-return-container {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	align-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.new-return-container p {
	color: #fff;
	font-weight: 600;
	font-size: 18px;
}

.new-return-info p {
	color: #FFBB02;
	font-size: 18px;
	font-weight: 600;
}

.content {
	height: 100%;
	overflow-x: auto;
}

.bets-container {
	display: block;
	padding: 0px 20px 0px 20px;
	background: #363D47;
	min-height: 100%;
}

.btn-small {
	width: 150px;
}

.form-input {
	width: 130px;
	padding: 8px 15px;
	background-color: #202833;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 300;
	color: #fff;
}

.form-input:focus {
	outline: 1px solid #4E545D;
}

select {
	background-image: url(/src/assets/images/arrow-select.svg);
	background-repeat: no-repeat;
	background-position: center right 14px;
}

.add-bets-items {
	padding-bottom: 10px;
	border-bottom: 1px dashed #4E545D;
	padding-top: 10px;
}

.add-bets {
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	color: #63A246;
}

.button-container-mobile {
	text-align: center;
}

@media screen and (max-width: 599px) {
	.betslip-button {
		width: 100%;
		border-radius: 0px;
	}

	.new-stake-container p {
		font-size: 15px;
	}

	.new-return-container p {
		font-size: 15px;
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 2px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.error_wrap {
	position: relative;
	padding: 2px 3px;
	border: 1px solid #D93444;
	background: #762835;
	border-radius: 5px;
	margin-top: 5px;
	margin-bottom: 10px;
	z-index: 9;
}

.error_wrap .detail {
	text-align: center;
	color: #fff;
	font-weight: 300;
	font-size: 12px;
	line-height: 20px;
}

.betslip-type {
	width: 130px;
	height: 32px;
	padding: 8px 15px;
	background-color: #202833;
	border-radius: 3px;
	display: flex;
	align-items: center;
}

.betslip-type p {
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	cursor: default;
}
</style>