<script setup>
/* eslint-disable */

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useInitializeStore } from '@/stores/initialize';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import { useLiveSportsStore } from '@/stores/live_sports';
import { EditBetSlip } from '@/stores/edit_betslip';
import MyEditBetsSlips from '@/views/Bets/MyEditBetsSlips.vue';
import Footer from '@/components/Common/Footer.vue';
import Header from '@/components/Common/header.vue';
import MobileMenu from '@/components/Common/mobileMenu.vue';
import MyBetsSlips from './Bets/MyBetsSlips.vue';
import Promotions from '@/components/live_sports/Promotions.vue';

import LiveSportSlider from '@/components/live_sports/LiveSportsSlider.vue';
import VirtualCourt from '@/components/live_sports/VirtualCourt.vue';
import VirtualCourtNew from '@/components/live_sports/VirtualCourtNew.vue'

const fixtureStore = useFixtureStore();
const liveSportsStore = useLiveSportsStore();
const editBetslipStore = EditBetSlip();
const router = useRouter();
const initializeStore = useInitializeStore();
const liveSportOverviewStore = useLiveSportOverview();
const mobile_active_tab = ref('virtual_court');


const goToOverview = () => {
	router.push( {name: 'live-sports-overview' });
}

const goToEventView = () => {
	liveSportOverviewStore.getFirstFixture
	router.push({ name: 'live-sports-eventview', params: { fixture_id: liveSportOverviewStore.getFirstFixture.id }});
}

const goToCompetition = (fixture) => {
	router.push({ name: 'pre-sports-competition', params: { sport_id: fixture.sport_id, region_id: Number(fixture.region_id), competition_id: fixture.competition_id } } )
}
</script>


<template>
	<div class="main_div">
		<Header></Header>
		<!-- fixed mobile bottom menu-->
		<div class="fixed-btm-main">
			<div class="container-full fix-padding">
				<MobileMenu></MobileMenu>
			</div>
		</div>

			<div class="sports-main">
				<div class="container-full">
					<div class="sports-main-outer live_Sports-main-outer" :class="{'eventview-top-sec': router.currentRoute.value.name == 'live-sports-eventview'}">
						<div class="sports-outer-left">

							<!-- left sidebar  -->
							<div class="sports-left-sidebar">
								<div class="tab desktop-tab-left">
									<button class="tablinks" :class="{'active': router.currentRoute.value.name == 'live-sports-overview'}" @click="goToOverview">{{$t('general.overview')}}</button>
									<button class="tablinks" :class="{'active': router.currentRoute.value.name == 'live-sports-eventview'}" @click="goToEventView">{{$t('general.eventview')}}</button>
								</div>
							</div>
							<!-- left sidebar end -->

							<!-- center grid start -->
							<div class="sports-center-content">
								<div class="highlight-tab-mobile live-sports-mobile-tab">
									<div class="tab">
										<button class="tablinks" :class="{'active': router.currentRoute.value.name == 'live-sports-overview'}" @click="goToOverview">{{$t('general.overview')}}</button>
										<button class="tablinks" :class="{'active': router.currentRoute.value.name == 'live-sports-eventview'}" @click="goToEventView">{{$t('general.eventview')}}</button>
									</div>
								</div>

								<div v-if="router.currentRoute.value.name == 'live-sports-eventview'" class="competetion-back-btn">
									<a @click="router.back()" class="">
										<svg xmlns="http://www.w3.org/2000/svg" width="4.414" height="7.414" viewBox="0 0 4.414 7.414">
											<g id="Group_232" data-name="Group 232" transform="translate(0.707 0.525)">
												<line id="Line_7" data-name="Line 7" x1="3" y2="3" transform="translate(0 0.182)" fill="none"
													stroke="#fff" stroke-linecap="round" stroke-width="1"></line>
												<line id="Line_8" data-name="Line 8" x1="3" y1="3" transform="translate(0 3.182)" fill="none"
													stroke="#fff" stroke-linecap="round" stroke-width="1">
												</line>
											</g>
										</svg>&nbsp; {{ $t('general.back') }}
									</a>
								</div>
								<!-- game filter slider start-->
								<div class="league-silder game-filter-slider">
									<div class="">
										<div id="game-top_Slider" class="league-slider-main game-slider_main loaded">
											<LiveSportSlider/>
										</div>

									</div>
								</div>
								<!-- game filter slider end-->

							</div>
							<div v-if="router.currentRoute.value.name == 'live-sports-eventview' && fixtureStore.fixture != null">
								<div class="live-sports-tabs">
									<div class="live-sport-title">
										<div class="game-icon">
											<img :src="liveSportsStore.liveSportWhiteIcon(fixtureStore.fixtureBook.sport_id)" alt="">
											<img class="region-icon" :src="'/assets' + fixtureStore.fixtureBook.region_icon_url" alt="">
										</div>
										<h6 @click="goToCompetition(fixtureStore.fixtureBook)">{{ fixtureStore.fixtureBook.region_name + ' - ' + fixtureStore.fixtureBook.competition_name }}</h6>
									</div>

									<div class="eventview-virtual-tabs">
										<div class="virtual-tab-inner" :class="{'active': fixtureStore.mobile_active_tab == 'virtual_court'}" @click="fixtureStore.setMobileActiveTab('virtual_court')">
											<svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.988" viewBox="0 0 19 13.988">
												<g id="Group_934" data-name="Group 934" transform="translate(-20.047 -558.794)">
												<g id="Group_933" data-name="Group 933" transform="translate(20.547 559.294)">
													<path id="path2506" d="M566.221-442h-2.9v-5.833h2.9Z" transform="translate(-548.221 451.406)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
													<path id="path2508" d="M16.237-442h-2.9v-5.833h2.9Z" transform="translate(-13.333 451.406)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
													<path id="path2510" d="M31.333-565.013h-18V-578h18Z" transform="translate(-13.333 578.001)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
													<g id="g2512" transform="translate(6.226 3.719)">
													<path id="path2514" d="M-197.06-98.531a2.774,2.774,0,0,1-2.774,2.774,2.774,2.774,0,0,1-2.774-2.774,2.774,2.774,0,0,1,2.774-2.774A2.774,2.774,0,0,1-197.06-98.531Z" transform="translate(202.609 101.305)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
													</g>
													<g id="g2516" transform="translate(9 0.083)">
													<path id="path2518" d="M0,0V12.822" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
													</g>
												</g>
												</g>
											</svg>
										</div>

										<div class="virtual-tab-inner" :class="{'active': fixtureStore.mobile_active_tab == 'scoreboard'}" @click="fixtureStore.setMobileActiveTab('scoreboard')">
											<svg xmlns="http://www.w3.org/2000/svg" width="17.945" height="18" viewBox="0 0 17.945 18">
												<g id="Group_1734" data-name="Group 1734" transform="translate(-281.203 -149.412)">
												<g id="Group_1733" data-name="Group 1733" transform="translate(281.703 149.412)">
													<path id="path3134" d="M100-301.333h4.044v2.545H100Z" transform="translate(-97.987 311.459)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
													<path id="path3144" d="M100-120.666h3.091v2.593H100Z" transform="translate(-97.939 135.558)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
													<path id="path3146" d="M100-662.665h14.884v2.593H100Z" transform="translate(-97.939 663.18)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
													<g id="g3148" transform="translate(0 0)">
													<path id="path3150" d="M0-664.665v-18" transform="translate(0 682.665)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
													</g>
													<path id="path3152" d="M100-482h10.178v2.545H100Z" transform="translate(-97.987 487.328)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1"/>
												</g>
												</g>
											</svg>
										</div>

									</div>

								</div>
							</div>
							<div v-if="router.currentRoute.value.name == 'live-sports-overview'" class="live-sports-center">
								<router-view></router-view>
							</div>
						</div>
						<div v-if="router.currentRoute.value.name == 'live-sports-overview'" class="sports-outer-right overview-right-outer">
							<template v-if="initializeStore.screenWidth > 1278">
								<VirtualCourt v-if="!initializeStore.betcomCourtEnabled"></VirtualCourt>
								<VirtualCourtNew v-else ></VirtualCourtNew>
							</template>

							<div class="sports-promotion-outer">
								<div class="promotion-header">
									<h2>{{ $t('general.promotions') }}</h2>
								</div>
								<div class="promotion-card-slider">
								  <Promotions></Promotions>
								</div>
							</div>

						</div>
					</div>
					<template v-if="router.currentRoute.value.name == 'live-sports-eventview'">
						<router-view></router-view>
					</template>
				</div>
			</div>

		<!-- footer start -->
		<MyEditBetsSlips v-if="initializeStore.isSportsbookEnabled && editBetslipStore.edit_mode_enabled" />
		<MyBetsSlips v-if="initializeStore.isSportsbookEnabled && !editBetslipStore.edit_mode_enabled"/>
		<Footer></Footer>
		<!-- footer end -->
	</div>
</template>
<style scoped>
.main_div{
	background-color: #202833;
}

@media screen and (min-width: 768px) and (max-width: 1281px) {
	.sports-main .live_Sports-main-outer {
		grid-template-columns: 1fr;
	}
}

/* @media screen and (min-width: 1279px) and (max-width: 1600px) {
	.sports-main .live_Sports-main-outer {
		grid-template-columns: 1fr 400px;
	}
} */

@media screen and (min-width: 320px) and (max-width: 991px) {
	.sports-main .eventview-top-sec .game-filter-slider {
		display:none;
	}

	.sports-main .live_Sports-main-outer .sports-center-content {
		display: block;
	}

	.sports-main .live_Sports-main-outer .sports-outer-left {
		grid-template-columns: 1fr;
	}

}

.sports-promotion-outer {
	max-width: 98vw !important;
}

@media screen and (min-width: 1026px) and (max-width: 1277px) {
	.sports-promotion-outer {
		margin-top: 0px !important;
		padding: 0 !important;
		border: 0 !important;
	}
}

html[dir="rtl"] .sports-main .eventview-top-sec .sports-outer-left .sports-left-sidebar .tab {
	margin-right: 20px;
}

html[dir="rtl"] .sports-left-sidebar .tab {
	margin-right: 20px;
}

html[dir="rtl"] .fix-padding {
	padding-left: unset;
	padding-right: unset;
}

.competetion-back-btn {
	display: none;
	margin-bottom: 5px;
}

.competetion-back-btn a {
	color: #fff;
	text-decoration: none;
	font-size: 12px;
	font-weight: 100;
}

.competetion-back-btn a svg {
	width: 4px;
	height: 8px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.competetion-back-btn {
		display: flex;
		padding-top: 3px;
	}

}

.region-icon {
	width: 21px !important;
	height: auto;
	margin-left: 5px;
}
</style>