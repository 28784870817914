 /* eslint-disable */
import { defineStore } from 'pinia';
import { BetSlip } from './BetSlip';
import { useCustomerStore } from './customer';
import { create, all } from 'mathjs'
import { EditBetSlip } from './edit_betslip';

export const useOddsStore = defineStore('odds', {
	state: () => ({
		odds_preview: null,
	}),
	getters: {
		getOddsPreview(state) {
			return state.odds_preview;
		},
		oddsFormat: (state) => (odd_price) => {
			if (state.odds_preview == 'decimal' || state.odds_preview == null) return odd_price;

			odd_price = Number(odd_price);

			// American odds
			if (state.odds_preview == 'american') {
				return state.getAmericanOdds(odd_price);
			}

			// Fractional odds
			if (state.odds_preview == 'fractional') {
				return state.getFractionalOdds(odd_price);
			}

			// Hong Kong Odds
			// if (state.odds_preview == 'hong_kong') {
			// 	return state.getHongKongOdds(odd_price);
			// }

			// Malay Odds
			// if (state.odds_preview == 'malay') {
			// 	return state.getMalayOdds(odd_price);
			// }

			// Indonesian Odds
			// if (state.odds_preview == 'indonesian) {
			// 	return state.getIndonesianOdds(odd_price);
			// }

			// Implied Odds
			// if (state.odds_preview == 'implied') {
			// 	return state.getImliedOdds(odd_price);
			// }
		},
		getFractionalOdds: (state) => (odd_price) => {
			const math = create(all, {})

			const a = math.number(odd_price - 1)
			const b = math.fraction(a)
			return b.n + '/' + b.d;
		},
		getAmericanOdds: (state) => (odd_price) => {
			if (odd_price >= 2.00) {
				let american_odd = (odd_price - 1) * 100;
				return Math.round(american_odd)
			} else {
				let american_odd = (-100) / (odd_price - 1)
				return Math.round(american_odd)
			}
		},
		// getHongKongOdds: (state) => (odd_price) => {
			// 	var hongKongOdds = odd_price - 1;

			// 	// Keeping it as a decimal for precision
		// 	return hongKongOdds.toFixed(2)
		// },
		// getIndonesianOdds: (state) => (odd_price) => {
		// 	var indoPositive = (odd_price - 1) * 100;
		// 	var indoNegative = (-1) * (odd_price - 1) * 100;
		// 	var impliedProbability = 1 / (odd_price - 1);

		// 	// Determine whether to show positive or negative Malay odds based on the implied probability
		// 	if (impliedProbability < 0.5)return indoPositive.toFixed(2);
		// 	else return indoNegative.toFixed(2)
		// },
		// getMalayOdds: (state) => (odd_price) => {
			// 	var malayPositive = (odd_price - 1) * 100;
		// 	var malayNegative = (-1) * 100 / (odd_price - 1);
		// 	var impliedProbability = 1 / (odd_price - 1);

		// 	// Determine whether to show positive or negative Malay odds based on the implied probability
		// 	if (impliedProbability < 0.5)return malayPositive.toFixed(2);
		// 	else return malayNegative.toFixed(2)
		// },
		// getImliedOdds: (state) => (odd_price) => {
		// 	var impliedProbability = 1 / odd_price;

		// 	// Calculate the implied odds as a percentage
		// 	var impliedOdds = impliedProbability * 100;

		// 	return impliedOdds.toFixed(2) + '%'; // Return the result rounded to 2 decimal places
		// },
		isOddInBetslip: () => (odd_uuid) => {
			if (BetSlip().betslip.bets.hasOwnProperty(odd_uuid)) return true;

			return false;
		},
		isOddInEditBetslip: () => (odd_uuid) => {
			if (EditBetSlip().betslip.bets.hasOwnProperty(odd_uuid)) return true;

			return false;
		},
		isOddSuspended: () => (odd) => {
			if (!odd) return true;

			if (odd.price == 1.00 && !odd.sp) return true;

			if (odd.hasOwnProperty('status') && odd.status == 'suspended') return true;

			return false;
		}
	},
	actions: {
		setOddsFormat(odd_format) {
			console.log(odd_format)
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/preferences/odds-format/update', { odds_format: odd_format }).then((response) => {
				this.odds_preview = odd_format;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		changeOddsPreview(odd_format) {
			const customerStore = useCustomerStore();

			if (!customerStore.isLoggedIn) this.odds_preview = odd_format;
			else this.setOddsFormat(odd_format);
		},
	},
	persist: {
		storage: localStorage,
		paths: ['odds_preview'],
	},
})