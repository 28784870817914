<!-- eslint-disable -->
<script setup>
import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useCustomerStore } from '@/stores/customer';
import { onMounted, ref } from 'vue';
import { closeModal, confirmModal } from '@kolirt/vue-modal'

const svgs_data = commonSvgs;
const customerStore = useCustomerStore();

const props = defineProps({
	title: {},
	body: {},
	notification: {}
})

const closeModalOnClickOutside = (event) => {
	// Check if the click occurred outside the modal
	if (!event.target.closest('.modal-content')) {
		closeModal();
		// Call the modalClosedCallback passed from the parent
		customerStore.customer_notification_id = null;
	}

	if (props.notification.read_at == null) {
		customerStore.updateCustomerNotification(props.notification).then(() => {
			customerStore.fetchCustomerNotifications({page: 1});
		});
	}
};

const goToUrl = () => {
	closeModal();

	if (props.notification.read_at == null) {
		customerStore.updateCustomerNotification(props.notification).then(() => {
			window.open(props.notification.button_link_url, '_self');
		});
	}
}

</script>
<template>

	<div class="modal-outer verify-email-popup" ref="modal" @click="closeModalOnClickOutside">
		<!-- overlay -->
		<div class="overlay"></div>
		<!-- modal -->
		<div class="modal">
			<button class="close" @click="closeModalOnClickOutside"  v-html="svgs_data.popup_cross"></button>

			<template v-if="props">
				<div class="modal-header">
					<h3>{{ props.title }}</h3>
				</div>
				<div class="password-reset-main">
					<div class="popup-message">
						<p v-html="props.body"></p>
					</div>
					<template v-if="props.notification?.button_link_url">
						<button class="btn btn-secondary" type="button" @click="goToUrl()">
							{{ props.notification?.button_name || $t('general.close')}}
						</button>
					</template>
					<template v-else>
						<button class="btn btn-secondary" type="button" @click="closeModalOnClickOutside">
							{{ props.notification?.button_name || $t('general.close')}}
						</button>
					</template>
				</div>
			</template>
		</div>

	</div>
</template>
<style scoped>
	.popup-message {
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
</style>