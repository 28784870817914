import { defineStore } from 'pinia';
import { useCustomerStore } from './customer';
import _ from 'lodash';

export const useCasinoStore = defineStore('casinoStore', {
	state: () => ({
		brands: {},
		games: {},
		favourites: {},
		last_played_games: {},
		load_more_games: {},
		more_games_fetched: {},
		selected_slot_provider_id: 'all',
		search_term: null,
		show_favourites: false,
		featured_slot_games: {},
		games_loading: false,
		show_more_providers: [],
		all_games_load_more: 10,
		total_games_per_provider: {},
		current_page: 1,
		games_loading_more: false,
		total_pages: 0,
		selected_category: null,
		categories: {},
		current_pages: {},
		snapshot_games_per_provider: 13
	}),
	getters: {
		getGames(state) {
			if (!state.games) return {};

			let favourites_last_played = _.concat(state.favourites, state.last_played_games);

			let games = _.concat(_.cloneDeep(state.games), favourites_last_played);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			// Sort games so that favourites are first
			games = _.sortBy(games, game => !game.favourite);

			// Remove duplicates, keeping the first occurrence of each id
			games = _.uniqBy(games, 'id');

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });

			not_favourite_games = _.orderBy(not_favourite_games, ['order', 'name'], ['asc', 'asc']);

			games = [...favourite_games, ...not_favourite_games];

			if (this.selected_category == null && (this.search_term == null || this.search_term == '')) {
				const slot_providers = Array.isArray(this.brands) ? this.brands : [];

				// Create a set of provider IDs that have `fetched` set to `true`
				const fetched_provider_ids = new Set(
					slot_providers.filter(provider => provider.fetched).map(provider => provider.id)
				);

				// Filter games by the providers that are marked as fetched
				games = _.filter(games, game => fetched_provider_ids.has(Number(game.slot_provider_id)));
			}

			if (this.selected_slot_provider_id && this.selected_slot_provider_id !== 'all') {
				games = _.filter(games, game => game.slot_provider_id === this.selected_slot_provider_id);
			}

			if (this.search_term) {
				games = _.filter(games, game => game.name.toLowerCase().includes(this.search_term.toLowerCase()));
			}

			games = _.sortBy(games, [slot => slot.label, slot => slot.id]);

			let grouped_games;

			if (this.selected_category) {
				const selected_category = _.find(this.categories, { id: this.selected_category });
				if (selected_category) {
					grouped_games = _.chain(games)
						.filter(game => { return _.some(game.categories_ids, { id: this.selected_category }); })
						.groupBy(() => selected_category.name)
						.mapValues(group => {
							return _.orderBy(group, [
								game => {
									const category_detail = _.find(game.categories_ids, { id: this.selected_category });
									return category_detail ? category_detail.order : 999999;
								},
								'name'
							], ['asc', 'asc']);

						})
						.value();
				} else {
					grouped_games = {};
				}

			} else {
				grouped_games = _.groupBy(games, 'label');
			}

			const providers = this.brands;

			let sorted_grouped_games = {};
			if (this.selected_category == null) {
				const sorted_providers = _.sortBy(providers, 'order');

				// Sort grouped games by provider order
				_.forEach(sorted_providers, provider => {
					if (grouped_games[provider.name]) {
						sorted_grouped_games[provider.name] = grouped_games[provider.name];
					}
				});
			} else {
				sorted_grouped_games = grouped_games;
			}

			_.forIn(sorted_grouped_games, (value, key) => {
				let show_more_key = key;
				if (this.selected_category == null) {
					let provider = _.find(providers, { name: key });
					show_more_key = provider.id;
				}

				const index = _.indexOf(this.show_more_providers, show_more_key);
				if (index === -1) {
					sorted_grouped_games[key] = _.slice(value, 0, this.snapshot_games_per_provider);
				}
			});

			return sorted_grouped_games;
		},
		getCategoryGamesCount(state) {
			let selected_category = _.find(state.categories, { id: state.selected_category });
			if (selected_category) {
				return selected_category.slots_count;
			}

			return 13;
		},
		getSlotProviderGamesCount: (state) => (game_provider_id) => {
			if (!state.games) return [];

			let games_count = state.total_games_per_provider[game_provider_id] ?? 0;

			return games_count;
		},
		getGamesCount(state) {
			return Object.keys(state.games).length;
		},
		getFavouriteGames(state) {
			if (!state.favourites) return {};

			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			let games = _.map(state.favourites, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});


			games = _.orderBy(games, ['favourite_timestamp', 'name'], ['desc', 'desc']);
			const index = this.show_more_providers.indexOf('show_favourites_games');

			if (index === -1) {
				games = games.slice(0, 13);
			}

			return games;
		},
		favoriteGamesCount(state) {
			if (!state.favourites) return 0;

			return state.favourites.length;
		},
		getSlotProvidersFormatted: (state) => (showFavourites = true) => {
			if (!state.brands) return [];

			const brands = _.map(state.brands, brand => ({
				id: brand.id,
				name: brand.name,
				slug: brand.slug,
				media: brand.media,
			}));

			if (showFavourites && state.favourites && state.favourites.length > 0) {
				return [{ id: 'all', name: 'All', slug: 'all' }, { name: 'Favourites', slug: 'favourites' }, ...brands];
			}

			return [{ id: 'all', name: 'All', slug: 'all' }, ...brands];
		},
		loadMoreBrandGames(state) {
			if (!state.load_more_games) return [];

			return state.load_more_games;
		},
		selectedProvider(state) {
			return state.selected_slot_provider_id;
		},
		searchTerm(state) {
			return state.search_term;
		},
		featuredSlotGames(state) {
			if (!state.games) return {};

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.filter(games, game => game.image_url !== null);
		},
		featuredSlotGamesFooter(state) {
			if (!state.games) return {};

			let games = _.cloneDeep(state.featured_slot_games);
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			games = _.map(games, game => {
				let favourite_record = _.find(favourites, { game_id: String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			let favourite_games = _.filter(games, { 'favourite': true });
			favourite_games = _.orderBy(favourite_games, ['favourite_timestamp'], ['desc']);

			let not_favourite_games = _.filter(games, { 'favourite': false });
			games = [...favourite_games, ...not_favourite_games];

			return _.chain(games).cloneDeep().filter(game => game.image_url !== null).take(8).value();
		},
		brandLogo() {
			return {
				'Ainsworth': '/assets/icons/casino/brands/ainsworth.svg',
				'Amatic': '/assets/icons/casino/brands/amatic.svg',
				'Apex': '/assets/icons/casino/brands/apex.svg',
				'Apollo': '/assets/icons/casino/brands/apollo.svg',
				'Aristocrat': '/assets/icons/casino/brands/aristocrat.svg',
				'EGT': '/assets/icons/casino/brands/egt.svg',
				'Fast Games': '',
				'Fish': '',
				'GClub': '/assets/icons/casino/brands/gclub.svg',
				'Habanero': '/assets/icons/casino/brands/habanero.svg',
				'Igrosoft': '/assets/icons/casino/brands/igrosoft.svg',
				'IGT': '/assets/icons/casino/brands/igt.svg',
				'Kajot': '/assets/icons/casino/brands/kajot.svg',
				'Live dealers': '',
				'Merkur': '/assets/icons/casino/brands/merkur.svg',
				'Microgaming': '/assets/icons/casino/brands/microgaming.svg',
				'More expensive': '',
				'Netent': '/assets/icons/casino/brands/netent.svg',
				'Novomatic': '/assets/icons/casino/brands/novomatic.svg',
				'Platipus': '/assets/icons/casino/brands/platipus.svg',
				'Pragmatic': '/assets/icons/casino/brands/pragmatic.svg',
				'QuickSpin': '/assets/icons/casino/brands/quickspin.svg',
				'Scientific Games': '/assets/icons/casino/brands/scientific.svg',
				'Sport Betting': '',
				'Table Games': '',
				'Tomhorn': '/assets/icons/casino/brands/tomhorn.svg',
				'Wazdan': '/assets/icons/casino/brands/wazdan.svg',
			}
		},
		showFavouritesGames(state) {
			return state.show_favourites;
		},
		getLastPlayedGames(state) {
			if (!state.games) return {};

			// Add favourite flag to the games
			let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;

			let last_played_games = _.map(state.last_played_games, game => {
				let favourite_record = null;
				favourite_record = _.find(favourites, { 'game_id': String(game.id) });

				return {
					...game,
					favourite: favourite_record ? true : false,
					favourite_timestamp: favourite_record ? favourite_record.created_at : null
				};
			});

			return _.orderBy(last_played_games, ['favourite_timestamp', 'name'], ['asc', 'asc']);
		},
		getShowMoreProvider(state) {

			return state.show_more_providers;
		},
		getTotalGamesPerProvider(state) {
			return state.total_games_per_provider;
		},
		getGamesLoading(state) {
			return state.games_loading;
		},
		getGamesLoadingMore(state) {
			return state.games_loading_more;
		},
		hasNextPage(state) {
			return state.current_page < state.total_pages ? true : false;
		},
		getCategories(state) {
			if (!state.categories) return [];

			return _.orderBy(state.categories, ['order'], ['asc']);

		},
		getSelectedCategoryId(state) {
			if (state.selected_category && state.selected_category.id) {
				let category_exists = _.find(state.categories, { 'id': state.selected_category.id });

				if (!category_exists) {
					this.selected_category = null;
					this.fetchGames();
					return null;
				}
			}

			return state.selected_category;
		},
		getSelectedSlotProviderId(state) {
			return state.selected_slot_provider_id;
		},
		getStorageUrl() {
			if (!process.env.VUE_APP_STORAGE_URL) return '';

			return process.env.VUE_APP_STORAGE_URL;
		},
		getSlotProviders(state) {
			return state.brands;
		},
		getSnapshotGamesPerProvider(state) {
			return state.snapshot_games_per_provider;
		}
	},
	actions: {
		fetchGames() {
			// If we're loading more games (i.e., page > 1), set games_loading_more
			let key = [
				this.selected_slot_provider_id || 'null',
				this.selected_category || 'null',
				this.search_term || 'null',
			].join('_');

			if (!this.current_pages[key]) {
				this.current_pages[key] = 1;
			}

			if (this.current_pages[key] > 1) {
				this.games_loading_more = true;
			} else {
				this.games_loading = true;
			}

			let device = 'desktop';

			let current_page = this.current_pages[key];

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				device = 'mobile';
			}
			let payload = {
				game_name: this.search_term,
				provider: this.selected_slot_provider_id,
				page: current_page,
				category_id: this.selected_category,
				device: device
			};

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/index', payload, { headers: { 'Version': 'v3' } }).then((response) => {
				if (this.current_page == 1 && !this.more_games_fetched['all']) {
					// On page 1, reset the games list
					this.games = response.data['games'];
					this.brands = response.data['providers'];
				} else {
					// Filter out games that already exist in the current games list
					const newGames = response.data['games'].filter(newGame =>
						!this.games.some(existingGame => existingGame.id === newGame.id)
					);
					// For next pages, push new games to the existing list if they don't already exist
					this.games.push(...newGames);
				}

				if (this.selected_category == null && (this.search_term == null || this.search_term == '')) {
					const fetched_provider_ids = new Set(response.data['games'].map(game => game.slot_provider_id));

					this.brands = this.brands.map(brand => ({
						...brand,
						fetched: brand.fetched || fetched_provider_ids.has(brand.id)
					}));
				}

				this.categories = response.data['categories'];
				this.favourites = response.data['favourites'];
				this.total_games_per_provider = response.data['total_count_per_provider'] ?? [];

				this.games_loading = false;
				this.games_loading_more = false; // Reset the "loading more" state
				this.total_pages = response.data['total_pages'];

				this.more_games_fetched['all'] = true ?? false;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				this.games_loading = false;
				this.games_loading_more = false; // Reset the "loading more" state
				console.log(error.response);

				return Promise.reject(error);
			});
		},
		fetchMoreBrandGames(provider) {
			let payload = {
				provider: provider
			};

			if (this.search_term) payload['game_name'] = this.search_term;

			payload['device'] = 'desktop';
			payload['category_id'] = this.selected_category ?? null;

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			// Fetch only if not fetched before
			if (!Object.hasOwnProperty.call(this.more_games_fetched, provider)) {
				return global.axios.post(process.env.VUE_APP_API_URL + '/slots/more', payload, { headers: { 'Version': 'v3' } }).then((response) => {
					this.more_games_fetched[provider] = true;
					this.games = [...this.games, ...response.data['games']];

					return Promise.resolve(response.data);
				}).catch((error) => {
					this.games_loading = false;
					console.log(error.response);

					return Promise.reject(error);
				})
			} else {
				return Promise.resolve();
			}


		},
		fetchLastPlayedGames() {
			let payload = {
				device: 'desktop'
			};

			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				payload['device'] = 'mobile';
			}

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/last-played', payload).then((response) => {
				this.last_played_games = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				console.log(error);

				return Promise.reject(error);
			})
		},
		showFavourites() {
			this.search_term = null;
			this.show_favourites = true;
			this.show_more_providers = [];
		},
		openGame(game_id, demo = false) {
			let protocol = window.location.protocol;
			let hostname = window.location.hostname;
			let port = window.location.port ? window.location.port : '';
			let close_url = protocol + '//' + hostname + (port ? ':' + port : '') + '/casino/close';

			let payload = {
				game_id: game_id,
				close_url: close_url
			};

			if (demo) payload['demo'] = true;

			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/opengame', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchFeaturedSlotGames(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/slots/featured', payload, { headers: { 'Version': 'v3' } }).then((response) => {
				this.featured_slot_games = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		toggleFavourite(game_id) {
			return global.axios.post(import.meta.env.VUE_APP_API_URL + '/account/favourite/slot', { id: game_id }).then((response) => {
				useCustomerStore().customer.favourites = response.data.data;

				let favourites = (useCustomerStore().customer && useCustomerStore().customer.favourites.slots.length > 0) ? useCustomerStore().customer.favourites.slots : null;
				let is_favourite = _.find(favourites, { game_id: String(game_id) });

				if (is_favourite) {
					let game = _.find(this.games, { 'id': game_id });

					if (!game) {
						game = _.find(this.last_played_games, { 'id': game_id });
					}

					if (game) {
						game.favourite = true;
						game.favourite_timestamp = is_favourite.created_at;

						this.favourites = [...this.favourites, game];
					}
				} else {
					let game = _.find(this.games, { 'id': game_id });

					if (game) {
						game.favourite = false;
						game.favourite_timestamp = null;
					}

					console.log('this.favourites', game_id, this.favourites);
					this.favourites = _.filter(this.favourites, game => game && game.id != game_id);
					console.log('this.favourites', game_id, this.favourites);
				}

				if (this.favourites.length == 0) {
					this.show_favourites = false;
					this.selected_slot_provider_id = 'all';
				}

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		showMoreToggle(provider) {
			const index = this.show_more_providers.indexOf(provider);

			if (index !== -1) {
				// If the provider is already in the array, remove it
				this.show_more_providers.splice(index, 1);

				return Promise.resolve();
			} else {
				if (provider == 'show_last_played_games' || provider == 'show_favourites_games') {
					this.show_more_providers.push(provider);

					return Promise.resolve();
				}

				if (this.selected_category) {
					this.show_more_providers.push(provider);
					return Promise.resolve();
				} else {
					return this.fetchMoreBrandGames(provider).then(() => {
						this.show_more_providers.push(provider);

						return Promise.resolve();
					}).catch(() => {
						return Promise.reject();
					});
				}
			}
		},
		loadMoreGames() {
			let key = [
				this.selected_slot_provider_id || 'null',
				this.selected_category || 'null',
				this.search_term || 'null',
			].join('_');

			if (!this.current_pages[key]) {
				this.current_pages[key] = 1;
			} else {
				this.current_pages[key]++;
			}

			this.fetchGames();
		},
		claimFreespinsAndPlay(freespin_id, slot_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/account/freespins/claim', { freespin_id: freespin_id, slot_id: slot_id }).then(response => {

				return Promise.resolve(response.data);
			}).catch(error => {
				return Promise.reject(error);
			});
		},
		cancelFreespin(freespin_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/v2/account/freespins/cancel', { freespin_id: freespin_id }).then(response => {

				return Promise.resolve(response.data);
			}).catch(error => {
				return Promise.reject(error);
			});
		},
		setSelectedSlotProviderId(slot_provider_id) {
			this.selected_slot_provider_id = slot_provider_id != null ? slot_provider_id : null;

			this.current_page = 1;
			this.show_favourites = false;

			this.fetchGames();
		},
		setSearchTerm(game_name) {
			this.search_term = game_name ?? null;
			this.current_page = 1;
			this.fetchGames();
		},
		selectCategory(category) {
			this.selected_category = category ? category.id : null;

			this.fetchGames();
		},
		setSnapshotGamesPerProvider(width) {
			let games_in_view = 13;
			if (width >= 0 && width <= 539) games_in_view = 5;
			else if (width >= 540 && width <= 670) games_in_view = 6;
			else if (width >= 671 && width <= 991) games_in_view = 9;
			else if (width >= 992 && width <= 1100) games_in_view = 5;
			else if (width >= 1101 && width <= 1359) games_in_view = 9;
			else if (width >= 1360 && width <= 1599) games_in_view = 9;

			this.snapshot_games_per_provider = games_in_view;
		}
	},
})